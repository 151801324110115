import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    root:{
        marginTop:theme.spacing.unit * 4,
        marginBottom:theme.spacing.unit * 16
    }
})

class DummyPadding extends React.Component {
    render() {
        return (
            <div className={this.props.classes.root}>
                <Typography variant="caption" gutterBottom align="center">&copy;2019 SonicHits.co</Typography>
            </div>
        );
    }
}

export default withStyles(styles)(DummyPadding);