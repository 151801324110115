import React from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Badge } from '@material-ui/core';
import Add from '@material-ui/icons/Add';

import { openDrawer } from '../redux/anim.redux';
import { updateLastPath } from '../redux/route.redux';
import {clearQuery} from '../redux/post.redux'
import SearchBar from './SearchBar';
import { retrievePosts, clearPostTable } from '../redux/post.redux';
import { notInUnread } from '../redux/anim.redux';
import { NUM_POSTS_PER_BATCH } from '../Glogbal_Config';
import {Search as FilterIcon} from '@material-ui/icons';

const styles = theme => {
    return ({
        root : {
            marginTop: theme.spacing.unit*2,
            marginBottom: theme.spacing.unit,
        },
        leftIcon: {
            marginLeft : 0,
            marginRight: theme.spacing.unit,
        },
        filterIcon: {
            width: 16,
            marginRight: theme.spacing.unit,
        }
    });
};

class ForumTopBar extends React.Component {

    clearQuery() {
        this.props.clearPostTable();
        this.props.retrievePosts(0, NUM_POSTS_PER_BATCH, 'post');
        this.props.history.push('/');
        this.props.notInUnread();
    }

    handler = () => {
        if (this.props.auth.isLoggedIn) this.props.openDrawer();
        else this.props.history.push('/login');
        this.props.updateLastPath('/');
    }

    render() {
        const QureyBtn = withStyles({
            root: {
              borderStyle: 'solid',
              borderWidth: '1px',
              borderColor: 'Grey',
              borderRadius: 4,
              border: 0,
              color: '#343a40',
              padding: '2px 8px',
              minWidth: '10px',
            },
            label: {
              textTransform: 'capitalize',
              fontSize: 14,
              fontWeight: 500,
            },
        })(Button);

        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Grid container direction="row" justify={this.props.query ? "space-between":"flex-end"} alignItems="center" spacing={8}>
                    {/* {this.props.isTicket || this.props.isMobile ? null : 
                        <Grid item xs="auto">
                            <SearchBar />
                        </Grid>
                    } */}
                    {this.props.query ? <Badge color='secondary' badgeContent={'X'} onClick={() => {this.clearQuery()}}><QureyBtn variant="outlined" size="small" color="inherit"><FilterIcon className={classes.filterIcon}/>{this.props.query.target}: {this.props.query.val}</QureyBtn></Badge> : null}
                    <Grid item xs="auto">
                        <Button variant="contained" color="primary" onClick={this.handler}><Add className={classes.leftIcon}></Add> {this.props.isTicket ? "New Ticket" : "New Topic"} </Button>
                    </Grid>
                </Grid>
            </div>
            
        );
    };
}

const mapStatetoProps = (state) => {return {auth : state.auth , query: state.posts.query}};
const actionCreators = { openDrawer, updateLastPath, clearQuery, retrievePosts, notInUnread, clearPostTable };

export default connect(mapStatetoProps, actionCreators)(withStyles(styles)(ForumTopBar));