import React from 'react';
import { connect } from 'react-redux';
import { Grid, IconButton, Typography } from '@material-ui/core';
import {Share, Delete } from '@material-ui/icons';
import { deleteComment } from '../redux/post.redux';
import { deleteTicketComment } from '../redux/ticket.redux'
import Clipboard from 'react-clipboard.js'
class CommentBoxFooterList extends React.Component {
    deleteHandle = () => {
        if (window.confirm('Sure you wanna delete it?')) {
            if (this.props.isTicket) {
                this.props.deleteTicketComment(this.props.postId, this.props.commentId);
            } else {
                this.props.deleteComment(this.props.postId, this.props.commentId);
            }
        }
    }

    getSharelink() {
        const index = this.props.index ? `#${this.props.index}` : '';
        return window.location.href.split('#').shift()+index;
    }

    shareClickHandler = () => {
        this.props.enqueue("comment url has copied to clipboard");
    }
    render() {
        return (
            <div>
                <Grid container justify="flex-end" alignItems="center" spacing={8} >
                    <Grid item xs="auto">
                        <Clipboard component="a" data-clipboard-text={this.getSharelink()} onSuccess={this.shareClickHandler}><IconButton><Share/></IconButton></Clipboard>
                    </Grid>
                    { (this.props.auth.role === 'super' || this.props.isArthur) && !this.props.isTopic ? 
                        <Grid item xs="auto">
                            <IconButton aria-label="delete" onClick={this.deleteHandle}><Delete/></IconButton>
                        </Grid>
                        : null
                    }
                    { this.props.index ? 
                        <Grid item xs="auto">
                            <Typography variant='button' gutterBottom>{`#${this.props.index}`}</Typography>
                        </Grid> : null
                    }
                </Grid>
            </div>
        );
    }
}
const mapStatetoProps = (state) => {return {auth : state.auth}};
const actionCreators = { deleteComment, deleteTicketComment };
export default connect(mapStatetoProps, actionCreators)(CommentBoxFooterList);