import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {IconButton, InputBase, Divider, Button} from '@material-ui/core'
import {Search as SearchIcon, Remove} from '@material-ui/icons'
const styles = theme => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        minWidth: 200,
        width: '20vw',
        border: '1px solid grey',
        height: 30,
    },
    input: {
        marginLeft: 8,
        flex: 1,
    },
    iconButton: {
        textTransform:'uppercase',
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },
});

class SearchBar extends React.Component {
    state = {searchFor: 'Topic', input: ''}

    updateSearchTarget = (target) => {
        this.setState(state => ({...state, searchFor: target}))
    }
    updateSearchTarget = (e) => {
        const target = e.target
        this.setState(state => ({...state, input: target.value}))
    }
    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <InputBase className={classes.input} placeholder='Search' onChange={this.updateSearchTarget}/>
                <IconButton className={classes.iconButton} aria-label='search'><SearchIcon/></IconButton>
                <Divider className={classes.divider} />
                <Button className={classes.iconButton} aria-label='searchTarget'>{this.state.searchFor}</Button>
            </div>
        )
    }
}

export default withStyles(styles)(SearchBar);