import axios from 'axios';
import { API_URL } from '../Glogbal_Config';
const RETRIEVE_MYACCOUNT_SUCCECESS = 'MYACCOUNT_RETRIEVED';
const UPDATE_AVATAR_SUCCECESS = 'AVATAR_UPDATED';
const RETRIEVE_USER_SUCCECESS = 'USER_RETRIEVED';
const UPDATE_AVATAR_FAILED = 'AVATAR_FAILED_TO_UPDATE';
const UPDATE_MYACCOUNT_SUCCESS = "MYACCOUNT_UPDATED";
const UPDATE_MYACCOUNT_FAILED = "MYACCOUNT_FAILED_TO_UPDATE";
const initState = {};
const initUserState = {};

export function updateMyAccount(state=initState, action) {
    switch(action.type) {
        case RETRIEVE_MYACCOUNT_SUCCECESS:
            let data = action.data;
            return { ...state, data };
        case UPDATE_AVATAR_SUCCECESS:
            return {...state, data: {...state.data, avatar: action.data}};
        case UPDATE_AVATAR_FAILED:
            return {...state};
        case UPDATE_MYACCOUNT_SUCCESS:
            state.updateSuccess = true
            return {...state};
        case UPDATE_MYACCOUNT_FAILED:
            state.updateSuccess = false
            return {...state};
        default:
            return state;
    }
}

export function getUserInfo(state=initUserState, action) {
    switch(action.type) {
        case RETRIEVE_USER_SUCCECESS:
            state = action.data;
            return { ...state}
        default:
            return state;
    }
}

export function retrieveMyAccount() {
    const token = window.localStorage.getItem('token');
    let config = { 
        headers: { token: token }
    };
    return dispatch => {
        axios.get(`${API_URL}/myAccount`, config)
        .then( (res) => dispatch({
            type : RETRIEVE_MYACCOUNT_SUCCECESS,
            data : res.data
        }));
    };
}

export function retrieveUserInfo(userID) {
    return dispatch => {
        axios.get(`${API_URL}/users/${userID}`)
        .then( (res) => dispatch({
            type : RETRIEVE_USER_SUCCECESS,
            data : res.data
        }));
    };
}



export function changeMyAvatar(avatar) {
    const token = window.localStorage.getItem('token');
    const formData = new FormData();
    formData.append('avatar', avatar);
    let config = { 
        headers: { 
            token: token,
            'conetnet-type': 'multiplart/form-data',
        }
    };
    return dispatch => {
        dispatch({type: UPDATE_AVATAR_SUCCECESS})
        axios.post(`${API_URL}/updateAvatar`, formData, config)
        .then( (res) => 
        {
            dispatch({
                type : UPDATE_AVATAR_SUCCECESS,
                data : res.data,
            })
        },
        ).catch(err => {
            dispatch({
                type : UPDATE_AVATAR_FAILED,
                data : err.response.data.message,
            })
        });
    };
}

export function changeMyAccount (userinfo) {
    const token = window.localStorage.getItem('token');
    let config = { 
        headers: { 
            token: token,
        },
    };
    return dispatch => {
        axios.put(`${API_URL}/myAccount`, userinfo, config)
        .then( (res) => 
        {
            dispatch({
                type : UPDATE_MYACCOUNT_SUCCESS,
                data : res.data,
            })
        },
        ).catch(err => {
            dispatch({
                type : UPDATE_MYACCOUNT_FAILED,
                data : err.response,
            })
        });
    };
}
