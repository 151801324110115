/* eslint-disable react/prop-types, react/jsx-handler-names */
import React from "react";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import classNames from "classnames";
import CreatableSelect from "react-select/lib/Creatable";

import { withStyles } from "@material-ui/core/styles";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import { Typography, NoSsr, TextField, Paper, Chip, MenuItem } from '@material-ui/core';
import CancelIcon from "@material-ui/icons/Cancel";
import {updateTags} from '../redux/editor.redux'

import { retrieveSuggestions } from '../redux/anim.redux';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: "flex",
    padding: 0,
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2
  }
});

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function _Control(props) {
  return (
    <TextField
      fullWidth
      error={props.hasError}
      helperText={props.errMsg}
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}
const Control = connect((state) => ({hasError: state.editor.tagError, errMsg: state.editor.tagErrorMsg}), {})(_Control);

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  Option,
  Placeholder,
  ValueContainer
};

class AutoCompleteTagBox extends React.Component {
  state = {
    multi: null
  };

  componentDidMount() {
    this.props.retrieveSuggestions();
  }


  handleChange = value => {
    this.props.updateTags(value.map(val => val.value));
    this.setState({
      multi: value
    });
  };

  render() {
    const { classes, theme } = this.props;

    const suggestions = this.props.suggestions.tags.map(tag => ({value: tag, label: tag}));

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        "& input": {
          font: "inherit"
        }
      })
    };

    return (
      <div className={classes.root}>
        <NoSsr>
          <CreatableSelect
            classes={classes}
            styles={selectStyles}
            textFieldProps={{
              InputLabelProps: {
                shrink: true
              }
            }}
            options={suggestions}
            components={components}
            value={this.state.multi}
            onChange={this.handleChange}
            placeholder="Tags"
            isMulti
          />
        </NoSsr>
      </div>
    );
  }
}

AutoCompleteTagBox.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
const mapStatetoProps = (state) => ({suggestions : state.autoSuggest, tags: state.editor.tags});
const actionCreators = {updateTags, retrieveSuggestions};
export default connect(mapStatetoProps, actionCreators)(withStyles(styles, { withTheme: true })(AutoCompleteTagBox));
