import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import { durationToCurrent } from '../Utils/DateTimeCal';

const styles = theme => ({
    author : {
        flexGrow: 1,
    }
})

class CommentTitle extends React.Component {
    render() {
        const { classes } = this.props;
        const arthur = (this.props.arthur !== undefined) ? this.props.arthur : '';
        const time = (this.props.time !== undefined) ? durationToCurrent(this.props.time) : '';
        return (
            <Grid container>
                <Typography className={classes.author} variant="body2" color="textSecondary" gutterBottom>
                    {arthur}
                </Typography>
                <Typography variant="body2" color="textSecondary" >
                    {`${time.duration} ${time.time}`}
                </Typography>
            </Grid>
        );
    }
}

export default withStyles(styles)(CommentTitle);