import { DateTime } from 'luxon';
const dateUnitMap = {
    "months" : "month",
    "days" : "d",
    "hours" : "h",
    "minutes" : "m"
}
export function durationToCurrent(lastUpdated) {
    let start = DateTime.fromISO(lastUpdated);
    let diffDays = start.diffNow(['months','days','hours','minutes']);
    let recent = { time : 'm', duration : 0 };
    for (let key in diffDays.values) {
        if (diffDays[key] !== 0) {
            recent.time = dateUnitMap[key];
            recent.duration = Math.round(-diffDays[key]);
            break;
        }
    }
    return recent;
}
export function compare(a, b) {
    let DateTime_A = DateTime.fromISO(a);
    let DateTime_B = DateTime.fromISO(b);
    return DateTime_A < DateTime_B;
}