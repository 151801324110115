import React from 'react';
import { connect } from 'react-redux';
import { Grid, TextField, FormControlLabel, Switch } from '@material-ui/core';
import AutoCompleteTagBox from './AutoCompleteTagBox';
import { updateTitle, updateProduct, updateSerial, updateSticky } from '../redux/editor.redux';

class NewPostTitle extends React.Component {
    state = {sticky: false}


    textFiledOnChangeHandle = (e) => {
        this.props.updateTitle(e.target.value);
    }
    productOnChangeHandle = (e) => {
        this.props.updateProduct(e.target.value);
    }
    serialOnChangeHandle = (e) => {
        this.props.updateSerial(e.target.value);
    }
    stickyOnChangeHandle = (e) => {
        this.props.updateSticky(!this.state.sticky);
        this.setState(state => ({...state, sticky: !state.sticky}));
        
    }

    

    render() {
        const productDetail = (
            <Grid container spacing={8}>
                <Grid item xs={6}><TextField error={this.props.productError} helperText={this.props.productErrorMsg} required fullWidth id="standard-bare" placeholder="Product Name" margin="normal" onChange={this.productOnChangeHandle}/></Grid>
                <Grid item xs={6}><TextField error={this.props.serialError} helperText={this.props.serialErrorMsg} required fullWidth id="standard-bare" placeholder="Serial Number" margin="normal" onChange={this.serialOnChangeHandle}/></Grid>
            </Grid>
        );
        return (
            <div>
                <Grid container direction="column">
                    <Grid item>
                        <TextField error={this.props.titleError} helperText={this.props.titleErrorMsg} required fullWidth id="standard-bare" placeholder="Topic" margin="normal" onChange={this.textFiledOnChangeHandle}/>
                    </Grid>
                    <Grid item>
                        {this.props.isTicket ? productDetail : <AutoCompleteTagBox/>}
                    </Grid>
                    {this.props.userRole === 'super' || this.props.userRole === 'admin' ?
                        <Grid item>
                            <FormControlLabel 
                                control={
                                    <Switch 
                                        checked={this.state.sticky} 
                                        onChange={this.stickyOnChangeHandle} 
                                        value='Sticky' 
                                        color='primary' />
                                } 
                                label='Sticky' 
                            />
                        </Grid>
                        :
                        null
                    }
                </Grid>
            </div>
        );
    }
}
const mapStatetoProps = (state) => ({titleError:state.editor.titleError, titleErrorMsg:state.editor.titleErrorMsg, contentError:state.editor.contentError, contentErrorMsg:state.editor.contentErrorMsg, productError: state.editor.productError, productErrorMsg : state.editor.productErrorMsg, serialError: state.editor.serialError, serialErrorMsg : state.editor.serialErrorMsg, userRole: state.auth.role});
const actionCreators = {updateTitle, updateSerial, updateProduct, updateSticky};
export default connect(mapStatetoProps, actionCreators)(NewPostTitle);