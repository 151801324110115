import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Drawer, Button } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { toggleDrawer, closeDrawer } from '../redux/anim.redux';
import { updateContent } from '../redux/editor.redux';

import DrawerButtonList from './DrawerButtonList';
import NewPostTitle from './NewPostTitle';
import NewReplyTitle from './NewReplyTitle';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const styles = theme => ({
  editorRoot: {
    padding: theme.spacing.unit * 2,
  },
  paper: {
    [theme.breakpoints.up('md')]: {
        top: '50%',
    },
    [theme.breakpoints.down('md')]: {
        top: '15%',
    }
  },
  homeWrapper: {
    marginTop: theme.spacing.unit * 1,
    minHeight: "30vh !important",
  },
  homeEditor: {
    border: "1px solid #F1F1F1 !important",
    borderRadius: "2px !important",
    minHeight: "30vh !important",
  }
});

class DraftEditDrawer extends React.Component {
    state = {
      bottom: false,
      editorState: EditorState.createEmpty()
    };
    onEditorStateChange = (editorState) => {
        this.props.updateContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
        this.setState({
          editorState,
        });
      };
    render() {
      const { classes } = this.props;
      const { editorState } = this.state;
      return (
        <div>
          <Drawer
            anchor="bottom"
            variant="persistent"
            open={this.props.drawer.drawerOpen}
            // onClose={this.props.toggleDrawer}
            classes={{paper: classes.paper}}
          >
          <div><Button aria-label="close" fullWidth onClick={this.props.closeDrawer}><ExpandMore /></Button></div>
          <div className={classes.editorRoot}>
            { this.props.type === 'comment' ? <NewReplyTitle title={this.props.title}/> : <NewPostTitle isTicket={this.props.isTicket}/>}
            <Editor
              editorState={editorState}
              spellCheck
              wrapperClassName={classes.homeWrapper}
              editorClassName={classes.homeEditor}
              onEditorStateChange={this.onEditorStateChange}
              toolbar={{
                options: ['emoji', 'embedded', 'image', 'inline', 'fontSize', 'link'],
                inline: { inDropdown: true },
                list: { inDropdown: false,},
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
              }}
            />
            <DrawerButtonList type={this.props.type} id={this.props.id} isTicket={this.props.isTicket}/>
          </div>
          </Drawer>
        </div>
      );
    }
  }
  
  DraftEditDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  const mapStatetoProps = (state) => {return { drawer : state.drawer }};
  const actionCreators = {toggleDrawer, closeDrawer, updateContent};
  export default connect(mapStatetoProps, actionCreators)(withStyles(styles)(DraftEditDrawer));