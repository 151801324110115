import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { Avatar, Divider, Grid } from '@material-ui/core';

import CommentTitle from './CommentTitle';
import CommentBoxFooterList from './CommentBoxFooterList';

import HTR from 'html-to-react';
import { NavHashLink } from 'react-router-hash-link'
import { AVATAR_URL } from '../Glogbal_Config';
import { parseName } from '../Utils/ParseName';
import UserInfo from './UserInfo'

const styles = theme => ({
    '@keyframes fadeIt' : {
        '0%':   { 'background-color': '#ffff99'},
        '100%' : { 'background-color' : '#ffffff' }
    },
    root : {
        flexGrow: 1,
        alignItems: 'flex-start',
    },
    active: {
        flexGrow: 1,
        animation: 'fadeIt 1s ease-in-out',
    },
    container: {
        position: 'relative',
    },
    avatar: {
        [theme.breakpoints.up('md')]: {
            margin: 0,
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: 60,
            height: 60,
            transform: 'translate(-50%, 20%)'
        },
        [theme.breakpoints.down('md')]: {
            top: 10,
            width: 60,
            height: 60,
            transform: 'translate(-10%, 0%)'
        }
    },
})

class CommentBox extends React.Component {
    state = {anchorEl: null}
    handleClick = event => {
        const target = event.currentTarget
        this.setState({anchorEl: target})
    }
    handleClose = () => {
        this.setState({anchorEl: null})
    }
    render() {
        const { classes } = this.props;
        let HtmlToReactParser = HTR.Parser;
        let htmlToReactParser = new HtmlToReactParser();
        const popover = Boolean(this.state.anchorEl)
        return(
            <div className={document.location.hash === `#${this.props.index}` ? classes.active :classes.root} id={this.props.index}>
                {this.props.index ? <NavHashLink to={`/#${this.props.index}`} location={{pathname: document.location.pathname + document.location.hash}}/> : null}
				<Grid container spacing={8}>
                    <Grid item xs={this.props.isMobile ? 12 : 1}>
                        <Grid container direction="column" justify="flex-start" alignItems="flex-start" className={classes.container}>
                            <a href='javascript:;'>
                                <Avatar 
                                    alt="avatar" 
                                    src={this.props.arthur !== undefined ? this.props.arthur.avatar !== undefined ? AVATAR_URL+this.props.arthur.avatar : null : null} 
                                    className={classes.avatar}
                                    aria-owns={popover ? 'userInfo-popover' : undefined}
                                    aria-haspopup = 'true'
                                    onClick={this.handleClick}
                                >
                                </Avatar>
                            </a>
                            <UserInfo history={this.props.history} anchorEl={this.state.anchorEl} open={popover} onClose={this.handleClose} userId={this.props.arthur !== undefined ? this.props.arthur._id : null}/>
                        </Grid>
                    </Grid>
                    <Grid item xs={this.props.isMobile ? 12 : 10} style={this.props.isMobile ? {marginTop: 10} : null}>
                        <CommentTitle arthur={(this.props.arthur!==undefined)?this.props.arthur.username:''} time={(this.props.time!==undefined)?this.props.time:''}></CommentTitle>
                        <div style={{lineHeight: 1.6}}>
                            {htmlToReactParser.parse(this.props.content)}
                        </div>
                        <CommentBoxFooterList isTopic={this.props.isTopic} isTicket={this.props.isTicket} postId={this.props.postId} commentId={this.props.commentId} isArthur={this.props.userId !== undefined && this.props.arthur !== undefined ? this.props.userId === this.props.arthur._id : null} index={this.props.index} enqueue={this.props.enqueue} justify="flex-end" color="default"></CommentBoxFooterList>
                    </Grid>
                </Grid>
                <Divider />
            </div>
        );
    }

}

export default withStyles(styles)(CommentBox);