import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import { Divider, Grid, CircularProgress } from '@material-ui/core';

import Post from '../Components/Post';
import CommentBox from '../Components/CommentBox';
import DraftEditDrawer from '../Components/DraftEditDrawer';
import DetailFooterList from '../Components/DetailFooterList';
import Navbar from '../Components/Navbar';

import { retrieveSingleTicket } from '../redux/ticket.redux';
import { clearEditorState } from '../redux/editor.redux';

import { mobile } from '../Utils/DeviceCheck';
import { withSnackbar } from 'notistack';

const styles = theme => ({
    root : {
        padding: theme.spacing.unit * 2,
    },
    post : {
        marginBottom: theme.spacing.unit * 1,
    },
    content : {
        transition: theme.transitions.create(['margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        marginBottom: 0,
    },
    contentShift : {
        transition: theme.transitions.create(['margin'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
        }),
        marginBottom: "50%",
    }
})

class TicketDetail extends React.Component {
	componentDidMount() {
        this.props.retrieveSingleTicket(this.props.match.params.ticketid);
        this.props.clearEditorState();
    }
    componentDidUpdate() {
        if (this.props.newComment !== undefined) {
            this.props.clearEditorState();
            this.props.retrieveSingleTicket(this.props.match.params.ticketid);
        }
    }
	render() {
        const { classes } = this.props;
        const { ticket } = this.props;
        const isMobile = mobile();
        const postComponent = (ticket.ticketDetailData !== undefined) ? <Post history={this.props.history} title={ticket.ticketDetailData.title} isTicket={true} isSolved={ticket.ticketDetailData.isSolved} tags={ticket.ticketDetailData.tags} id={ticket.ticketDetailData._id} product={ticket.ticketDetailData.product}/> : <CircularProgress/>;
        const topicComponent = (ticket.ticketDetailData !== undefined) ? <CommentBox isTopic={true} isTicket={true} arthur={ticket.ticketDetailData.arthur} isMobile={isMobile} content={ticket.ticketDetailData.content} time={ticket.ticketDetailData.lastUpdated}></CommentBox> : <CircularProgress/>;
        const commentComponent = (ticket.ticketDetailData.comments !== undefined) ? ticket.ticketDetailData.comments.map(val => (<CommentBox isTopic={false} isTicket={true} postId={ticket.ticketDetailData._id} isMobile={isMobile} commentId={val._id} key={val.timestamp} arthur={val.arthur} content={val.content} time={val.timestamp}/>)) : <CircularProgress />;
        const contentWidth = (mobile()) ? 12 : 10;
        const stepper = (mobile()) ? null : (<Grid item xs={2}></Grid>)
        return (
			<div className={classes.root}>
                <Navbar name="Navbar" companyName="Sonic Hits" websiteProp="Ticket" history={this.props.history}/>
                <div className={classNames(classes.content, {
                    [classes.contentShift]: this.props.drawer.drawerOpen,
                })}>
                    <Grid container spacing={8}>
                        <Grid item xs={contentWidth}>
                            <div className={classes.post}>
                                {postComponent}
                            </div> 
                            <Divider />
                            { topicComponent }
                            { commentComponent }
                            <DetailFooterList isTicket={true} justify="flex-start" color={this.props.ticket.ticketDetailData.isSolved ? "secondary" : "inherit"} id={this.props.ticket.ticketDetailData._id} enqueue={this.props.enqueueSnackbar}></DetailFooterList>
                        </Grid>
                        {stepper}
                    </Grid>
                </div>
                <div>
                    <DraftEditDrawer type="comment" isTicket={true} title={this.props.ticket.ticketDetailData.title} id={this.props.ticket.ticketDetailData._id}></DraftEditDrawer>
                </div>
			</div>
		)
	}
}

const mapStatetoProps = (state) => {return { ticket : state.ticket, drawer : state.drawer, newComment : state.editor.ticketId}};
const actionCreators = {retrieveSingleTicket, clearEditorState};
export default connect(mapStatetoProps, actionCreators)(withStyles(styles)(withSnackbar(TicketDetail)));