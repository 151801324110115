import React from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

import PostTable from '../Components/PostTable';
import ForumTopBar from '../Components/ForumTopBar';
import OnBottomListener from '../Components/OnBottomListener';
import DraftEditDrawer from '../Components/DraftEditDrawer';
import Navbar from '../Components/Navbar';
import DummyPadding from '../Components/DummyPadding';

import { retrievePosts, retrievePostsByTag, retrievePostsByArthur, proccessForumQuery, clearQuery, clearQueryErr } from '../redux/post.redux';
import { clearEditorState } from '../redux/editor.redux';

import { mobile } from '../Utils/DeviceCheck';

import { withSnackbar } from 'notistack';
import { NUM_POSTS_PER_BATCH } from '../Glogbal_Config'

const styles = theme => ({
	root : {
		padding : theme.spacing.unit * 2
	}
});

class Forum extends React.Component {
	lastLength = 0;
	state = {search: null, queryStr: ''}
	componentDidMount() {
		this.handleQuery()
		this.props.clearEditorState();
	}

	handleQuery() {
		if (this.props.location.search) {
			this.props.proccessForumQuery(this.props.location.search)
		} else {
			if (!this.props.posts.isProcessingQuery) this.props.retrievePosts(0, NUM_POSTS_PER_BATCH, 'post');
			this.setState({search: this.props.location.search})
		}
	}


	componentDidUpdate() {
		if (this.props.newTopic !==undefined) {
			this.props.history.push(`/post/${this.props.newTopic}`);
		}

		

		if (this.props.location.search !== this.state.search && !this.props.posts.isProcessingQuery) {
			this.props.proccessForumQuery(this.props.location.search);
			this.setState({search: this.props.location.search});
		}
		
		if (this.props.posts.retrieveErr && !this.props.posts.isProcessingQuery) {
			if (this.props.posts.retrieveErr === 'jwt malformed') {
				this.props.enqueueSnackbar('Please login first');
				this.props.history.push('/login')
				this.props.clearQuery();
			}
			this.props.clearQueryErr();
			// this.props.clearEditorState();
			return;
		}

		// this.props.clearEditorState();
	}

	onBottom = () => {
		if (!this.props.drawerOpen) {
			const lastId = this.props.posts.postTableData[this.props.posts.postTableData.length - 1]._id;
			if (this.lastLength === this.props.posts.postTableData.length) this.props.enqueueSnackbar(`There's a line here`);
			this.lastLength = this.props.posts.postTableData.length;
			this.props.retrievePosts(lastId, NUM_POSTS_PER_BATCH, 'post');
		}
	}

	render() {
		const isMobile=mobile();
		return (
			<div className={this.props.classes.root}>
				<Navbar name="Navbar" companyName="Sonic Hits" websiteProp="Forum" history={this.props.history}/>
				<ForumTopBar isTicket={false} history={this.props.history} isMobile={isMobile} searchStr={this.state.queryStr}></ForumTopBar>
				<Divider />
				<PostTable posts={this.props.posts} history={this.props.history} />
				<DraftEditDrawer type="topic" isMobile={isMobile}></DraftEditDrawer>
				<DummyPadding></DummyPadding>
				<OnBottomListener onBottom={this.onBottom} offSet={10}></OnBottomListener>
			</div>
		)
	}
}

const mapStatetoProps = (state) => {return { posts : state.posts, newTopic : state.editor.postId, unread : state.unread.unread, drawerOpen: state.drawer.drawerOpen}};
const actionCreators = {retrievePosts, clearEditorState, retrievePostsByTag, retrievePostsByArthur, proccessForumQuery, clearQuery, clearQueryErr};
export default connect(mapStatetoProps, actionCreators)(withSnackbar(withStyles(styles)(Forum)));