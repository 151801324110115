import axios from 'axios';
import { API_URL, NUM_POSTS_PER_BATCH  } from '../Glogbal_Config';
import { compare } from '../Utils/DateTimeCal';
import {updateTags} from './editor.redux'

const RETRIEVE_POSTS_SUCCECESS = 'RETRIEVE_POSTS';
const RETRIEVE_POSTS_BY_QUERY_SUCCESS = 'RETRIEVE_POSTS_BY_QUERY_SUCCESS';
const RETRIEVE_POSTS_BY_QUERY_FAILED = 'RETRIEVE_POSTS_BY_QUERY_FAILED';
const RETRIEVE_SINGLE_POST_SUCCESS = 'RETRIEVE_SINGLE_POST';
const RETRIEVE_UNREAD_POST_SUCCESS = 'RETRIEVE_UNREAD_POST_SUCCESS';
const initState = {postTableData : [], postDetailData : {}, query: null, isProcessingQuery: false, retrieveErr: null};

const SUBMIT_TOPIC_SUCCESS = 'SUBMIT_TOPIC_SUCCESS';
const SUBMIT_COMMENT_SUCCESS = 'SUBMIT_COMMENT_SUCCESS';

const DELETE_TOPIC_SUCCESS = 'DELETE_TOPIC_SUCCESS';
const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';

const FOLLOW_TOPIC_SUCCESS = 'FOLLOW_TOPIC_SUCCESS';
const UNFOLLOW_TOPIC_SUCCESS = 'UNFOLLOW_TOPIC_SUCCESS';
const FORUM_QUERY_PROCCESSED = 'FORUM_QUERY_PROCCESSED';
const CLEAR_QUERY_ERR = 'CLEAR_QUERY_ERR'

const CLEAR_POST_STATE = 'CLEAR_POST_STATE';
const CLEAR_POST_TABLE = 'CLEAR_POST_TABLE';
const CLEAR_QUERY = 'CLEAR_QUERY'
const PROCESSING_QUERY = 'PROCESSING_QUERY'


// reducer to fetch post info
export function updatePostContent(state=initState, action) {
    let data = null;
    switch(action.type) {
        case RETRIEVE_POSTS_SUCCECESS:
            data = action.data;
            if (data.length === 0) return { ...state };
            if (state.postTableData !== undefined && state.postTableData.length !== 0) {
                let idx = 0;
                for (let item of data) {
                    if (item._id === state.postTableData[0]._id) break;
                    idx++;
                }
                if (idx === data.length && compare(data[data.length-1].lastUpdated, state.postTableData[0].lastUpdated)) {
                    state.postTableData.push(...data);
                } else {
                    for (let i = 0; i < idx; i++) state.postTableData.unshift(data[i]);
                }
            } else {
                state.postTableData.push(...data);
            }
            return { ...state };

        case RETRIEVE_SINGLE_POST_SUCCESS:
            data = action.data;
            state.isProcessingQuery = false
            state.postDetailData = data;
            return { ...state };
        case RETRIEVE_UNREAD_POST_SUCCESS:
            state.postTableData = action.data.unreadUpdates.map(update=>(update.item));
            return { ...state };
        case FOLLOW_TOPIC_SUCCESS:
            data = action.data;
            state.success = 'follow';
            return { ...state };
        case UNFOLLOW_TOPIC_SUCCESS:
            data = action.data;
            state.success = 'unfollow';
            return { ...state };
        case CLEAR_POST_STATE:
            state.success = undefined;
            return { ...state };
        case CLEAR_POST_TABLE:
            state.postTableData.length = 0;
            return { ...state };
        case FORUM_QUERY_PROCCESSED:
            state.query = action.data;
            return { ...state };
        case CLEAR_QUERY: 
            state.query = null;
            state.isProcessingQuery = false;
            state.retrieveErr = null;
            return { ... state };
        case PROCESSING_QUERY:
            state.isProcessingQuery = true;
            return { ...state };
        case RETRIEVE_POSTS_BY_QUERY_SUCCESS:
            state.postTableData = action.data;
            state.isProcessingQuery = false;
            state.retrieveErr = null;
            return { ...state };
        case RETRIEVE_POSTS_BY_QUERY_FAILED:
            state.isProcessingQuery = false;
            state.retrieveErr = action.data;
            return { ...state };
        case CLEAR_QUERY_ERR:
            state.retrieveErr = null;
            return { ...state };
        default:
            return state;
    }
}
// action creators related to fetch post info
export function retrievePosts(from, amount) {
    const url = `${API_URL}/posts`;
    const config = {headers:{from:from, amount:amount}};
    return dispatch => {
        axios.get(url, config)
        .then( (res) => dispatch({
            type : RETRIEVE_POSTS_SUCCECESS,
            data : res.data
        }));
    };
}

export function retrievePostsByTag(tag) {
    const url = `${API_URL}/posts`;
    const config = {headers:{tags: tag}};
    return dispatch => {
        axios.get(url, config)
        .then( (res) => dispatch({
            type : RETRIEVE_POSTS_BY_QUERY_SUCCESS,
            data : res.data
        }));
    };
}

export function retrievePostsByArthur(id) {
    const url = `${API_URL}/posts`;
    const config = {headers:{arthur: id}};
    return dispatch => {
        axios.get(url, config)
        .then( (res) => dispatch({
            type : RETRIEVE_POSTS_BY_QUERY_SUCCESS,
            data : res.data
        }));
    };
}

export function retrieveSinglePost(id) {
    const token = window.localStorage.getItem('token');
    const config = token ? {headers:{token : token}} : null;
    return dispatch => {
        axios.get(`${API_URL}/posts/${id}`, config)
        .then(
            res => dispatch({
                type : RETRIEVE_SINGLE_POST_SUCCESS,
                data : res.data
            })
        );
    }
}

export function proccessForumQuery(query) {
    const queries = (query).split('?').pop().split('&')
    let state = ''
    let value = ''
    for (const i in queries) {
        const query = queries[i];
        const obj = query.split('=');
        const key = obj.shift();
        const val = obj.pop();
        if (key === 'tags') {
            state = 'tags'
            val.split(',').forEach(tag => {
                value += `${tag} `
            });
            value = value.trim()
        }
        if (key === 'arthur') {
            state = 'arthur'
            value = val;
        }
        if (key === 'state') state = val
    }
    switch (state) {
        case 'arthur': 
            return dispatch => {
                const url = `${API_URL}/getUsernameByID/${value}`
                dispatch({type: PROCESSING_QUERY})
                axios.get(url).then(res => {
                    dispatch({
                        type: FORUM_QUERY_PROCCESSED,
                        data: {target: state, val: res.data.username}
                    })
                })
                .then( () => {
                        const url = `${API_URL}/posts`;
                        const config = {headers:{arthur: value}};
                        axios.get(url, config)
                        .then( res => dispatch({
                            type : RETRIEVE_POSTS_BY_QUERY_SUCCESS,
                            data : res.data
                        }));
                }).catch (err=> {
                        dispatch({type: RETRIEVE_POSTS_BY_QUERY_FAILED, data: err.response.data.message})
                    })
                }
        case 'tags':
            return dispatch => {
                dispatch({type: PROCESSING_QUERY})
                const url = `${API_URL}/posts`;
                const config = {headers:{tags: value}};
                axios.get(url, config)
                .then( (res) => dispatch({
                    type : RETRIEVE_POSTS_BY_QUERY_SUCCESS,
                    data : res.data
                })).catch (err=> {
                    dispatch({type: RETRIEVE_POSTS_BY_QUERY_FAILED, data: err.response.data.message})
                }).then (() => dispatch(updateTags(value.split(' '))));
                dispatch({type: FORUM_QUERY_PROCCESSED, data: {target: state, val: value}})
            }
        case 'unread':
            return dispatch => {
                dispatch({type: PROCESSING_QUERY})
                const url = `${API_URL}/myUnreadUpdates`;
                let config = { 
                    headers: { token: window.localStorage.getItem('token') }
                }
                axios.get(url, config)
                .then((res)=>dispatch({
                    type : RETRIEVE_POSTS_BY_QUERY_SUCCESS,
                    data : res.data.unreadUpdates.map(update=>(update.item)),
                })).catch (err=> {
                    dispatch({type: RETRIEVE_POSTS_BY_QUERY_FAILED, data: err.response.data.message})
                });
                dispatch({type: FORUM_QUERY_PROCCESSED, data: {target: state}})
            }
        case 'bookmarks':
            return dispatch => {
                dispatch({type: PROCESSING_QUERY})
                const token = window.localStorage.getItem('token');
                let config = { 
                    headers: { token: token }
                };
                axios.get(`${API_URL}/myFollowedPosts`, config)
                .then( (res) => dispatch({
                    type : RETRIEVE_POSTS_BY_QUERY_SUCCESS,
                    data : res.data.followedPosts
                })).catch (err=> {
                    dispatch({type: RETRIEVE_POSTS_BY_QUERY_FAILED, data: err.response.data.message})
                });
                dispatch({type: FORUM_QUERY_PROCCESSED, data: {target: state}})
            };
        default: 
            return dispatch => {
                dispatch({type: CLEAR_QUERY});
                dispatch(updateTags([]));
        };
    }
}

export function clearQuery() {
    return {type: CLEAR_QUERY}
}

export function clearQueryErr() {
    return {type: CLEAR_QUERY_ERR}
}

export function retrieveUnreadPost() {
    const url = `${API_URL}/myUnreadUpdates`;
    let config = { 
        headers: { token: window.localStorage.getItem('token') }
    }
    return dispatch => {
        axios.get(url, config)
        .then((res)=>dispatch({
            type : RETRIEVE_UNREAD_POST_SUCCESS,
            data : res.data
        }));
    }
}

export function submitNewTopic(val) {
    let token = window.localStorage.getItem('token');
    let config = { 
        headers: { token: token }
    }
    val = {
        title : val.title,
        content : val.content,
        tags : val.tags,
        sticky: val.sticky
    }
    return dispatch => {
        axios.post(`${API_URL}/posts`, val, config)
        .then(
            res => dispatch({
                type : SUBMIT_TOPIC_SUCCESS,
                data : res
            })
        );
    }
}

export function submitNewComment(val, id) {
    const token = window.localStorage.getItem('token');
    const config = {
        headers: { token : token }
    }
    val = {content : val.content};
    return dispatch => {
        axios.post(`${API_URL}/posts/${id}`, val, config)
        .then(
            res => dispatch({
                type : SUBMIT_COMMENT_SUCCESS,
                data : res
            })
        );
    }
}

export function deleteTopic(id) {
    const token = window.localStorage.getItem('token');
    const config = {
        headers: { token : token }
    };
    return dispatch => {
        dispatch({type: PROCESSING_QUERY})
        axios.delete(`${API_URL}/posts/${id}`, config)
        .then(
            res => dispatch({
                type : DELETE_TOPIC_SUCCESS
            })
        ).then ( () => {
            const url = `${API_URL}/posts`;
            const config = {headers:{from:0, amount: NUM_POSTS_PER_BATCH}};
            axios.get(url, config)
            .then( (res) => dispatch({
                type : RETRIEVE_POSTS_SUCCECESS,
                data : res.data
            }))});
    }
}

export function deleteComment(topic_id, comment_id) {
    const token = window.localStorage.getItem('token');
    const config = {
        headers: { token : token }
    };
    return dispatch => {
        axios.delete(`${API_URL}/posts/${topic_id}/${comment_id}`, config)
        .then(
            res => dispatch({
                type : DELETE_COMMENT_SUCCESS
            })
        ).then(
            () => {
                const token = window.localStorage.getItem('token');
                const config = token ? {headers:{token : token}} : null;
                axios.get(`${API_URL}/posts/${topic_id}`, config)
                .then(
                    res => dispatch({
                        type : RETRIEVE_SINGLE_POST_SUCCESS,
                        data : res.data
                    })
                );            
            }
        )
    }
}

export function subscribeTopic(topic_id) {
    const token = window.localStorage.getItem('token');
    const config = {
        headers: { token : token }
    };
    return dispatch => {
        axios.get(`${API_URL}/posts/${topic_id}/follow`, config)
        .then(
            res => dispatch({
                type : FOLLOW_TOPIC_SUCCESS,
                data : res.data
            })
        )
    }
}

export function unsubscribeTopic(topic_id) {
    const token = window.localStorage.getItem('token');
    const config = {
        headers: { token : token }
    };
    return dispatch => {
        axios.get(`${API_URL}/posts/${topic_id}/unfollow`, config)
        .then(
            res => dispatch({
                type : UNFOLLOW_TOPIC_SUCCESS,
                data : res.data
            })
        )
    }
}

export function retrieveFollowedPosts() {
    const token = window.localStorage.getItem('token');
    let config = { 
        headers: { token: token }
    };
    return dispatch => {
        axios.get(`${API_URL}/myFollowedPosts`, config)
        .then( (res) => dispatch({
            type : RETRIEVE_POSTS_BY_QUERY_SUCCESS,
            data : res.data
        }));
    };
}

export function clearPostState(){
    return { type : CLEAR_POST_STATE };
}

export function clearPostTable(){
    return { type : CLEAR_POST_TABLE };
}