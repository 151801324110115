import React from 'react';

import Grid from '@material-ui/core/Grid';
import { Divider, Link, withStyles, Typography } from '@material-ui/core';
import {Done, HourglassEmpty} from '@material-ui/icons';

const styles = theme => {
    return {
        root : {
            width: '100%'
        }
    };
}

class TicketBoxMobile extends React.Component {
    routeToDetail = () => {
        this.props.history.push(`/ticket/${this.props.id}`);
    }
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <div>
                    <Grid container justify="space-between" alignItems="center"> 
                        <Grid item><Link onClick={this.routeToDetail} component="button" color="inherit" variant="body1">{this.props.isSolved ? <Done style={{color:'green', marginLeft: 12, marginRight: 12}}/> : <HourglassEmpty style={{color:'DarkGoldenRod', marginLeft: 12, marginRight: 12}}/>}{this.props.title}</Link></Grid>
                        <Grid item><Typography variant="body1" color='textSecondary'>{`${this.props.time.duration}${this.props.time.time}`}</Typography></Grid>
                    </Grid>
                </div>
                <div>
                    <Divider></Divider>
                </div>
            </div>
        );
    }
}
export default withStyles(styles)(TicketBoxMobile);