import React from 'react';
import { debounce } from '../Utils/Debounce';
class OnBottomListener extends React.Component {
    componentDidMount() {
		window.addEventListener('scroll', this.debouncedHandleScroll);
	}
    componentWillUnmount() {
		window.removeEventListener('scroll', this.debouncedHandleScroll);
    }
    handleScroll= (e) => {
		const scrollNode = document.scrollingElement || document.documentElement
		const offSet = this.props.offSet !== undefined ? this.props.offSet : 0;
		if (
			scrollNode != null &&
			scrollNode.scrollHeight - offSet <= scrollNode.scrollTop + window.innerHeight
		) {
			this.props.onBottom();
		}
	}
	debouncedHandleScroll = debounce(this.handleScroll, 200);
	render() { return null; }
}
export default OnBottomListener;