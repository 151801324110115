import React from 'react';
import PropTypes, { element } from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Typography, TextField, CircularProgress, Button, InputAdornment, Divider, Avatar, MenuItem } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import deepOrange from '@material-ui/core/colors/deepOrange';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import { AVATAR_URL } from '../Glogbal_Config';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faLink, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faInstagram, faYoutube, faVimeo, faGithub, faGooglePlus, faItunes, faSpotify, faSoundcloud } from '@fortawesome/free-brands-svg-icons';
import { withSnackbar } from 'notistack';
import { Prompt } from 'react-router-dom';
import { CountryRegionData } from 'react-country-region-selector';

library.add(faFacebook, faTwitter, faLink, faInstagram, faYoutube, faVimeo, faGithub, faGooglePlus, faItunes, faSpotify, faSoundcloud, faTrashAlt);

const styles = theme => ({
    container: {
        textAlign: 'left',
        margin: 'auto',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    bioTextField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        [theme.breakpoints.up('lg')] : {
            width: '60%',
        },
        [theme.breakpoints.down('lg')] : {
            width: '90%',
        },
        minHeight: '200',
    },
    dense: {
        marginTop: 16,
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    fab: {
        margin: theme.spacing.unit,
        float: 'right',
    },
    avatar: {
        marginTop: 50,
        marginBottom: 50,
        width: 150,
        height: 150,
        color: '#fff',
        fontSize: 35,
        textTransform: 'uppercase',
        backgroundColor: deepOrange[500],
    },
    input: {
        display: 'none',
    },
});


class MyAccountTable extends React.Component {

    constructor(props) {
        super(props);
        this.classes = props;
        this.state = {dialogOpen: false, gotUserinfo: false, avatarNeedsUpdate: false, modified: false};
    }
    

    componentDidUpdate(prevProps) {
        if (prevProps.myAccount !== this.props.myAccount) {
            if (!this.state.gotUserinfo) {
                this.setState(state => ({...state, userinfo: this.props.myAccount.data, gotUserinfo: true}));
            }
            if (this.state.avatarNeedsUpdate && this.props.myAccount.data.avatar) {
                this.setState(state => ({
                    ...state, 
                    userinfo: {...state.userinfo, avatar: this.props.myAccount.data.avatar}, 
                    avatarNeedsUpdate: false,
                }));
            }
        }
        if (this.props.myAccount.updateSuccess !== undefined && this.state.modified) {
            if (this.props.myAccount.updateSuccess) {
                this.props.enqueueSnackbar('Account Updated')
                this.setState(state=>({...state, modified: false}))
            } else {
                this.props.enqueueSnackbar('Account Failed To Update')
            }
        }
        if (this.state.gotUserinfo && this.state.userinfo.country && !this.state.countryIndex) {
            const country = this.state.userinfo.country
            const index = CountryRegionData.findIndex(function(item) {
                return item[0] === country
            });
            this.setState(state=>({...state, countryIndex: index}))
        }
    }
    
    componentWillUnmount() {
        this.setState(state => ({...state, gotUserinfo: false, modified: false}));
    }

    handleDialogOpen = () => {
        this.setState({errMessage: undefined, tmpURL: undefined, dialogOpen: true});
    }

    handleDialogCancel = () => {
        this.setState({errMessage: undefined, tmpURL: undefined, dialogOpen: false});
    }

    handleDialogClose = () => {
        if (this.state.tmpURL) {
            const valid = this.validateURL(this.state.tmpURL);
            if (valid) {
                let name = 'link';
                const url = this.state.tmpURL;
                for (var key in this.socialSiteChecker) {
                    if (this.socialSiteChecker[key].test(this.state.tmpURL)) {
                        name = key;
                        break;
                    }
                }
                this.addSocialLink(name, url);
            }
            this.setState({dialogOpen: !valid, tmpURL: undefined});
        } else {
            this.setState({dialogOpen: false, tmpURL: undefined});
        }
    }

    validateURL = (url) => {
        const regex = /^((http[s]?|ftp):\/)?\/?([^:\s]+)((\w+)*)([\w]+[^#?\s]+)(.*)?(#[\w]+)?$/;
        if (!regex.test(url)) {
            this.setState({errMessage: 'Invalid URL'});
            return false;
        } else {
            this.setState({errMessage: undefined});
            return true;
        }
    }

    handleAvatarUpdate = (file) => {
        this.setState(state => ({...state, avatarNeedsUpdate:true}),()=> {
            this.props.updateAvatar(file);
        })
        
    }

    handleUpdate = () => {
        const emailTester = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const validEmail = emailTester.test(this.state.userinfo.email)
        if (!validEmail) {
            this.props.enqueueSnackbar('Please enter a valid email')
            return
        }
        if (this.state.userinfo.username.length === 0) {
            this.props.enqueueSnackbar('Username can not be empty')
            return
        }
        this.props.updateAccount(this.state.userinfo);
    }

    socialSiteChecker = {
        facebook : /(?:https?:\/\/)?(?:www\.)?facebook.com\/.(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w]*\/)*([\w]*)/,
        twitter : /(?:https?:\/\/)?(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/,
        instagram : /(?:https?:\/\/)?(?:www\.)?instagram\.com\/([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)/,
        googlePlus : /(?:https?:\/\/)?plus\.google\.com\/\+[^/]+|\d{21}/,
        linkedin : /(?:https?:\/\/)?(?:www\.)?linkedin\.com\/in\/(A-z 0-9 _ -)\/?/,
        github : /(?:https?:\/\/)?(?:www\.)?github\.(com|io)\/?/,
        youtube : /(?:https?:\/\/)?(?:www\.)?youtu(be|.be)?(\.com)?\/.+/,
        soundcloud : /(?:https?:\/\/)?(?:www\.)?(soundcloud\.com|snd\.sc)\/(.*)$/,
        vimeo : /(?:https?:\/\/)?(www\.)?(vimeo\.com\/)(\w+)/,
        spotify : /(?:https:\/\/)?(open\.spotify\.com|spotify)\/([A-Za-z0-9_]+)\/([A-Za-z0-9_]+)/,
        itunes : /(?:https?:\/\/)?(itunes\.apple\.com)/,
        netEase : /(?:https?:\/\/)?(music\.163\.com)/,
        tencent : /(?:https?:\/\/)?(y\.qq\.com)/,
        xiami : /(?:https?:\/\/)?(www\.)?(xiami)\.com\/(\w+)/,
    }


    setTempUrl = (event) => {
        this.setState({tmpURL: event.target.value, errMessage: undefined});
    }

    
    handleChange = name => event => {
        let userinfo = {...this.state.userinfo};
        let item = {...userinfo[name]};
        item = event.target.value;
        userinfo[name] = item;
        this.setState(state => ({
            ...state,
            userinfo: userinfo,
            modified: true
        }));
    };

    handleLocChange(name, event, index) {
        
        let userinfo = {...this.state.userinfo};
        let item = {...userinfo[name]};
        item = event.target.value;
        userinfo[name] = item;
        userinfo['region'] = null
        this.setState(state => ({
            ...state,
            userinfo: userinfo,
            countryIndex: index,
            modified: true
        }));
    };
   

    handleSocialLinkChange = link => event => {
        const url = event.target.value;
        let items = [...this.state.userinfo.socialLinks];
        let item = {...items[link.index]};
        item.url = event.target.value;
        const valid = this.validateURL(url);
        if (valid) {
            let name = 'link';
            for (var key in this.socialSiteChecker) {
                if (this.socialSiteChecker[key].test(url)) {
                    name = key;
                    break;
                }
            }
            item.name = name;
        }
        items[link.index] = item;
        this.setState( state => ({...state, userinfo: {...state.userinfo, socialLinks: items}, modified: true}));
    }

    addSocialLink = (name, url) => {
        let item = {name, url};
        this.setState( state => ({
            ...state,
            userinfo: {
                ...state.userinfo,
                socialLinks: state.userinfo.socialLinks.concat(item)
            },
            modified: true
        }))
    }

    removeSocialLink = (link) => {
        let items = [...this.state.userinfo.socialLinks];
        items.splice(link, 1);
        this.setState( state => ({...state, userinfo: {...state.userinfo, socialLinks: items}, modified: true}));
    }

    countryList = CountryRegionData.map((item, index) => (
        <MenuItem key={item[1]} value={item[0]} index={index}>
            {item[0]}
        </MenuItem>
    ))



    render() {
        let tableBody = undefined;
        let {modified} = this.state;
        const { classes } = this.props;
        const regionList = this.state.countryIndex ? CountryRegionData[this.state.countryIndex][2].split('|').map(item => {
            const name = item.split('~')
            return (
            <MenuItem key={name[1]} value={name[0]}>
                {name[0]}
            </MenuItem>
        )}) : <MenuItem/>
        if (!this.state.gotUserinfo) {
            tableBody = <CircularProgress/>;
        } else {
            let socialLinks = [];
            if (this.state.userinfo.socialLinks.length > 0) {
                socialLinks = this.state.userinfo.socialLinks.map((link, i) => 
                <div className={classes.textField} key={i}>
                    <TextField
                        id="outlined-website"
                        index = {i}
                        value={link.url}
                        onChange={this.handleSocialLinkChange({index: i})}
                        style={{width: '100%', height: 50}}
                        margin="normal"
                        variant="outlined"
                        type="url"
                        InputProps = {{
                            startAdornment: 
                            <InputAdornment position="start">
                                <Button color="primary" size="small" style={{maxWidth: '40px', maxHeight: '40px', minWidth: '40px', minHeight: '40px'}} onClick={()=>window.open(link.url, '_blank')}><FontAwesomeIcon size='lg' icon={link.name in library.definitions.fab ? ['fab',link.name] : ['fas','link']}/></Button>
                            </InputAdornment>,
                            endAdornment:
                            <InputAdornment position="end">
                                <Button color="secondary" size="small" style={{maxWidth: '40px', maxHeight: '40px', minWidth: '40px', minHeight: '40px'}} onClick={()=>this.removeSocialLink(i)}><DeleteIcon/></Button>
                            </InputAdornment>
                        }}
                    />
                </div>
                )
            }
            
            return(
                <form className={classes.container} noValidate autoComplete="off">
                    <div>
                        <input
                            accept="image/*"
                            className={classes.input}
                            id="outlined-button-file"
                            onChange={(e)=>this.handleAvatarUpdate(e.target.files[0])}
                            type="file"
                        />
                        <label htmlFor="outlined-button-file">
                            <Button component="span">
                                <Avatar key={new Date()} alt={this.state.userinfo.username} src={this.state.userinfo.avatar ? AVATAR_URL+this.state.userinfo.avatar+'?'+(this.state.avatarNeedsUpdate ? `${Date.now()}` : null) : undefined} className={classes.avatar}>
                                    {(this.state.userinfo.firstName && this.state.userinfo.lastName) ? this.state.userinfo.firstName[0]+this.state.userinfo.lastName[0] : this.state.userinfo.username[0]}
                                </Avatar>
                            </Button>
                        </label>
                    </div>
                    <div>
                        <Typography variant="h6" className={classes.textField}>Contact Info</Typography>
                        <div>
                            <TextField
                                id="outlined-username"
                                label="Username"
                                className={classes.textField}
                                value={this.state.userinfo.username}
                                onChange={this.handleChange('username')}
                                margin="normal"
                                variant="outlined"
                                required
                            />
                            <TextField
                                id="outlined-email"
                                label="Email"
                                className={classes.textField}
                                value={this.state.userinfo.email}
                                onChange={this.handleChange('email')}
                                margin="normal"
                                variant="outlined"
                                required
                            />
                        </div>
                        <div>
                            <TextField
                                id="outlined-fisrtName"
                                label="First Name"
                                className={classes.textField}
                                value={this.state.userinfo.firstName}
                                onChange={this.handleChange('firstName')}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                id="outlined-lastName"
                                label="Last Name"
                                className={classes.textField}
                                value={this.state.userinfo.lastName}
                                onChange={this.handleChange('lastName')}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                id="outlined-suffix"
                                label="Suffix"
                                className={classes.textField}
                                value={this.state.userinfo.suffix}
                                onChange={this.handleChange('suffix')}
                                margin="normal"
                                variant="outlined"
                            />
                        </div>
                    </div>
                    <div>
                    <div>
                            <TextField
                                id="outlined-country"
                                label="Country"
                                select
                                className={classes.textField}
                                value={this.state.userinfo.country ? this.state.userinfo.country : 'Country'}
                                style={{minWidth: 100}}
                                onChange={(event, child) => this.handleLocChange('country', event, child.props.index)}
                                margin="normal"
                                variant="outlined"
                            >
                                {this.countryList}
                            </TextField>
                            <TextField
                                id="outlined-region"
                                label="Region"
                                select
                                className={classes.textField}
                                value={this.state.userinfo.region ? this.state.userinfo.region : 'Region'}
                                onChange={this.handleChange('region')}
                                style={{minWidth: 100}}
                                margin="normal"
                                variant="outlined"
                            >
                                {regionList}
                            </TextField>
                        </div>
                    </div>
                    <div>
                            <TextField
                                id="outlined-Bio"
                                label="Bio"
                                className={classes.bioTextField}
                                value={this.state.userinfo.bio}
                                onChange={this.handleChange('bio')}
                                margin="normal"
                                variant="outlined"
                                multiline
                                rows='6'
                                inputProps={{ maxLength: 150 }}
                            />
                    </div>
                    <div>
                        <Typography variant="h6" className={classes.dense}>Social Links</Typography>
                        <div>
                            {(socialLinks.length > 0) ? '' : <Typography variant="body2" className={classes.dense}>No Record Found</Typography>}
                        </div>
                        <div>
                            {socialLinks}
                        </div>
                        <div>
                            <Button className={classes.textField} variant="outlined" size="medium" color="secondary" onClick={()=>this.handleDialogOpen()} style={{marginTop: 30}}>Add</Button>
                            <div>
                                <Dialog open={this.state.dialogOpen} onClose={()=>this.handleDialogClose()} aria-labelledby="form-dialog-title">
                                    <DialogTitle id="form-dialog-title">Add Link</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>Add a new link to your profile for others to check out.<br/><br/>Website, SoundCloud, YouTube, Spotify, Instagram, Facebook, Twitter...</DialogContentText>
                                        <TextField error={this.state.errMessage !== undefined} autoFocus margin="dense" id="name" label={this.state.errMessage === undefined ? "URL" : this.state.errMessage} type="url" fullWidth onChange={this.setTempUrl}/>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={()=>this.handleDialogCancel()} color="primary">Cancel</Button>
                                        <Button onClick={()=>this.handleDialogClose()} color="primary">Add</Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                            <Divider variant='fullWidth' style={{marginTop: 30, marginBottom:30}}/>
                        </div>
                        <Button className={classes.textField} variant="contained" size="large" color="primary" onClick={()=>this.handleUpdate()} style={{marginBottom: 100}}>Update Profile</Button>
                        <Prompt when={modified} message='You have unsaved changes, sure you wanna leave?'/>
                    </div>    
                </form>
            )
        }
        return (
            <div>
                {tableBody}
            </div>
        )
    }    
}

MyAccountTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withSnackbar(withStyles(styles)(MyAccountTable));