import axios from 'axios';
import { API_URL } from '../Glogbal_Config';
// define action names
const ID_CHECK = 'ID_CHECK';
const LOGOUT = 'LOGOUT';
const LOGIN_SUCCEED = 'LOGIN_SUCCEED';
const LOGIN_FAILED = 'LOGIN_FAILED';
const SIGNUP_SUCCESS = 'SIGNED_UP';
const SIGNUP_FAILED = 'SIGNUP_FAILED';
const RETRIVE_NUMNOTIFS_SUCCECESS = 'RETRIVE_NUMNOTIFS_SUCCECESS';
const CHANGE_PASSWORD_EMAIL_SENT = 'EMAIL_SENT'
const SEND_CHANGE_PASSWORD_EMAIL_FALIED = 'FAILED_TO_SEND_EMAIL'
const CHANGE_PASSWORD_SUCCESS = 'PASSWORD_CHANGED'
const CHANGE_PASSWORD_FALIED = 'PASSWORD_CHANGE_FAILED'

// init state
const initState = {isLoggedIn : false, userId : '', avatar: ''};
// reducer for authentication
export function auth(state=initState, action) {
    switch(action.type) {
        case ID_CHECK:
            state.isLoggedIn = true;
            state.userId = action.data._id;
            state.avatar = action.data.avatar
            state.role = action.data.role
            return {...state};
        case LOGOUT:
            state.isLoggedIn = false;
            return {...state, isLoggedIn : false};
        case LOGIN_SUCCEED:
            let token = action.data;
            window.localStorage.setItem('token', token);
            return {...state, err : null, isLoggedIn : true};
        case LOGIN_FAILED:
            let err = action.data;
            return {...state, err : err};
        case SIGNUP_SUCCESS:
            window.localStorage.setItem('token', action.data);
            return {...state, err: null, isLoggedIn: true};
        case SIGNUP_FAILED:
            return {...state, err: action.data};
        case CHANGE_PASSWORD_EMAIL_SENT:
            state.emailSuccess = true
            return {...state}
        case SEND_CHANGE_PASSWORD_EMAIL_FALIED:
            state.emailSuccess = false
            return {...state, err: action.data}
        case CHANGE_PASSWORD_SUCCESS:
            state.passwordChangeSuccess = true
            return {...state}
        case CHANGE_PASSWORD_FALIED:
            state.passwordChangeSuccess = false
            return {...state, err: action.data}
        default:
            return state;
    }
}
// action creators for authtication
export function idCheck() {
    const token = window.localStorage.getItem('token');
    return dispatch => {
        if (token) {
            axios.post(`${API_URL}/idCheck`, {'token':token})
            .then((res) => dispatch(
                {
                type : ID_CHECK,
                data : res.data
            }))
            .then( () => {
                const url = `${API_URL}/myUnreadUpdates`;
                let config = { 
                    headers: { token: window.localStorage.getItem('token') }
                }
                axios.get(url, config)
                .then((res)=>dispatch({
                    type : RETRIVE_NUMNOTIFS_SUCCECESS,
                    data : res.data
                }));
            } )
            .catch(err => {
                window.localStorage.removeItem('token');
                console.log(err)
            });
        }
    }
}
export function logIn(auth) {
    return dispatch => {
        axios.post(`${API_URL}/login`, auth)
		.then(res => {
            dispatch({
                type : LOGIN_SUCCEED,
                data : res.data.token
            })
        })
        .then( () => {
            const url = `${API_URL}/myUnreadUpdates`;
            let config = { 
                headers: { token: window.localStorage.getItem('token') }
            }
            axios.get(url, config)
            .then((res)=>dispatch({
                type : RETRIVE_NUMNOTIFS_SUCCECESS,
                data : res.data
            }));
        })
        .then( () => {
            axios.post(`${API_URL}/idCheck`, {'token':window.localStorage.getItem('token')})
            .then((res) => dispatch(
                {
                type : ID_CHECK,
                data : res.data
            }))
        })
        .catch(err => {
            dispatch({
            type : LOGIN_FAILED,
            data : err.response.data.message
            })
        });
    }
}

export function signup(auth) {
    return dispatch => {
        axios.post(`${API_URL}/signup`, auth)
		      .then(res => {
                  dispatch({
                    type : SIGNUP_SUCCESS,
                    data : res.data.token
                })}).catch(err => {
                    dispatch({
                    type : SIGNUP_FAILED,
                    data : err.response.data.message
                })});
    }
}

export function forgotPassword(email) {
    return dispatch => {
        axios.post(`${API_URL}/forgotPassword`, email)
		      .then(res => {
                  dispatch({
                    type : CHANGE_PASSWORD_EMAIL_SENT,
                    data : res.data.token
                })}).catch(err => {
                    dispatch({
                    type : SEND_CHANGE_PASSWORD_EMAIL_FALIED,
                    data : err.response.data.message
                })});
    }
}

export function changePassword(auth) {
    const config = auth.token ? {headers:{token : auth.token}} : null;
    return dispatch => {
        axios.post(`${API_URL}/changePassword`, {password: auth.password}, config)
		      .then(res => {
                  dispatch({
                    type : CHANGE_PASSWORD_SUCCESS,
                    data : res.data.token
                })}).catch(err => {
                    dispatch({
                    type : CHANGE_PASSWORD_FALIED,
                    data : err.response.data.message
                })});
    }
}

export function logOut() {
    return { type : LOGOUT };
}