import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';


import Forum from './Container/Forum';
import Login from './Container/Login';
import Signup from './Container/Signup';
import PostDetail from './Container/PostDetail';
import MyAccount from './Container/MyAccount';
import TicketDashboard from './Container/TicketsDashboard';
import TicketDetail from './Container/TicketDetail';
import AdminConsole from './Container/AdminConsole';
import ForgotPassword from './Container/FogotPassword';
import ChangePassword from './Container/ChangePassword';

import { idCheck } from './redux/auth.redux';
import { mobile } from './Utils/DeviceCheck';

import { SnackbarProvider } from 'notistack';


const styles = (theme) => {
  let _subRoot = mobile() ? {marginLeft:0, marginRight:0} : {marginLeft:"17%", marginRight:"17%"};
  return ({
    toolbar: theme.mixins.toolbar,
    subRoot: _subRoot
  });
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.Notfound = () => <h1>404 Page Not Found</h1>;
  }

  componentDidMount() {
    this.props.idCheck();
  }

  render() {
    const { classes } = this.props;
    return (
      <SnackbarProvider maxSnack={3}>
      <Router>
        <div>
          <div className={classes.subRoot}>
            <div className={classes.toolbar} />
            <Switch>
              <Route path="/login" render={(props) => <Login {...props}/>} />
              <Route path="/signup" component={Signup} />
              <Route path="/myAccount" component={MyAccount} />
              <Route path="/admin" component={AdminConsole} />
              <Route path="/" exact={true} render={(props) => <Forum {...props}/>} />
              <Route path="/post/:postid" component={PostDetail} />
              <Route path="/ticketDashboard" component={TicketDashboard} />
              <Route path="/ticket/:ticketid" component={TicketDetail} />
              <Route path="/forgotPassword" component={ForgotPassword} />
              <Route path="/resetPassword" component={ChangePassword} />
              <Route component={this.Notfound} />
            </Switch>
          </div>
        </div>
      </Router>
      </SnackbarProvider>
    );
  }
}

const mapStatetoProps = (state) => {return {auth : state.auth}};
const actionCreators = {idCheck};
export default connect(mapStatetoProps, actionCreators)(withStyles(styles)(App));