import React from 'react';

import Grid from '@material-ui/core/Grid';
import { Avatar, Divider, Typography, Button, withStyles, Link } from '@material-ui/core';

import { AVATAR_URL } from '../Glogbal_Config';
import { parseName } from '../Utils/ParseName';
import {Star as StarIcon} from '@material-ui/icons';

const styles = theme => {
    return {
        tag : {
            marginBottom: theme.spacing.unit
        }
    };
}

const TagButton = withStyles({
	root: {
	  borderStyle: 'solid',
	  borderWidth: '1px',
	  borderColor: 'Grey',
	  borderRadius: 4,
	  border: 0,
	  color: '#343a40',
	  padding: '0px 8px',
	  minWidth: '10px',
	},
	label: {
	  textTransform: 'capitalize',
	  fontSize: 12,
	  fontWeight: 500,
	},
  })(Button);

class PostTopicBoxMobile extends React.Component {
    routeToDetail = () => {
        this.props.history.push(`/post/${this.props.id}`);
    }
    routeToArthur = (arthur) => {
        this.props.history.push(`?arthur=${arthur}`)
    }
    render() {
        const { classes } = this.props;
        return (
            <div style={this.props.sticky ? {background:'WhiteSmoke'} : null}>
                <div className={classes.tag}>
                    <Grid container spacing={0} justify="space-around" alignItems="center">
                        <Grid item xs={2}> 
                            <Avatar alt="avatar" onClick={()=>this.routeToArthur(this.props.arthur._id)}src={this.props.arthur !==undefined ? this.props.arthur.avatar !== undefined ? AVATAR_URL+this.props.arthur.avatar : null : null}>{ this.props.arthur !== undefined ? parseName(this.props.arthur.username) : null }</Avatar> 
                        </Grid>
                        <Grid item xs={10}>
                            <Grid container direction="column">
                                <Grid item> 
                                    {this.props.sticky ? <StarIcon color='primary'/> : null}
                                    <Link onClick={this.routeToDetail} variant="h6" color="inherit" component="button" noWrap>{this.props.title ? this.props.title : ""}</Link>
                                </Grid>
                                <Grid item>
                                    <Grid container justify="space-between" >
                                        {this.props.tags ? <Grid item><TagButton variant="outlined" size="small" color="inherit" onClick={this.routeToDetail}>{this.props.tags[0]}</TagButton></Grid> : null}
                                        <Grid item><Typography variant="body2">{this.props.time}</Typography></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <Divider></Divider>
                </div>
            </div>
        );
    }
}
export default withStyles(styles)(PostTopicBoxMobile);