import React from 'react';
import { Typography } from '@material-ui/core';

class NewReplyTitle extends React.Component {
    render() {
        return (
            <div>
                <Typography>Reply on thread: </Typography>
                <Typography variant="h6">{this.props.title}</Typography>
            </div>
        );
    }
}
export default NewReplyTitle;