const UPDATE_LAST_PATH = 'UPDATE_LAST_PATH';
const initState = { lastPath : '' };
export function updateRoute(state=initState, action) {
    switch(action.type) {
        case UPDATE_LAST_PATH:
            state.lastPath = action.data;
            return { ...state };
        default:
            return state;
    }
}

export function updateLastPath(route) {
    return { type : UPDATE_LAST_PATH, data : route };
}