import axios from 'axios';
import { API_URL } from '../Glogbal_Config';
const RETRIVE_TICKETS_SUCCECESS = 'RETRIVE_TICKETS';
const RETRIVE_SINGLE_TICKET_SUCCESS = 'RETRIVE_SINGE_TICKET';
const RETRIVE_ALLTICEKTES_SUCCECESS = 'RETRIVE_ALLTICEKTES_SUCCECESS';
const DELETE_COMMENT_SUCCESS = 'COMMENT_DELETED';
const initState = {ticketTableData : [], ticketDetailData : {}};

const SUBMIT_TICKET_SUCCESS = 'SUBMIT_TICKET_SUCCESS';
const SUBMIT_TICKET_COMMENT_SUCCESS = 'SUBMIT_TICKET_COMMENT_SUCCESS';
const MARK_TICKET_SOLVED = 'MARK_TICKET_SOLVED';

export function updateTicketContent(state=initState, action) {
    let data = null;
    switch(action.type) {
        case RETRIVE_TICKETS_SUCCECESS:
            data = action.data.tickets;
            state.ticketTableData.push(...data);
            return { ...state };
        case RETRIVE_SINGLE_TICKET_SUCCESS:
            data = action.data;
            state.ticketDetailData = data;
            return { ...state };
        case RETRIVE_ALLTICEKTES_SUCCECESS:
            data = action.data;
            state.ticketTableData.push(...data);
            return { ...state };
        case MARK_TICKET_SOLVED:
            state.ticketDetailData.isSolved = true
            return { ...state };
        default:
            return state;
    }
}

export function retrieveTickets() {
    const url = `${API_URL}/getMyTickets`;
    const token = window.localStorage.getItem('token');
    const config = { headers: { token:token } };
    
    return dispatch => {
        axios.get(url, config)
        .then( (res) => dispatch({
            type : RETRIVE_TICKETS_SUCCECESS,
            data : res.data
        }));
    };
}

export function retrieveAllTickets() {
    const url = `${API_URL}/tickets`;
    const token = window.localStorage.getItem('token');
    const config = { headers : { token : token } };
    return dispatch => {
        axios.get(url, config)
        .then( (res) => dispatch({
            type : RETRIVE_ALLTICEKTES_SUCCECESS,
            data : res.data
        }));
    };
}

export function retrieveSingleTicket(id) {
    const config = {headers: { token:window.localStorage.getItem('token') }};
    return dispatch => {
        axios.get(`${API_URL}/tickets/${id}`, config)
        .then(
            res => dispatch({
                type : RETRIVE_SINGLE_TICKET_SUCCESS,
                data : res.data
            })
        );
    }
}

export function deleteTicketComment(id, comment_id) {
    const token = window.localStorage.getItem('token');
    const config = {
        headers: { token : token }
    };
    return dispatch => {
        axios.delete(`${API_URL}/tickets/${id}/${comment_id}`, config)
        .then(
            res => dispatch({
                type : DELETE_COMMENT_SUCCESS
            })
        ).then(
            () => {
                const token = window.localStorage.getItem('token');
                const config = token ? {headers:{token : token}} : null;
                axios.get(`${API_URL}/tickets/${id}`, config)
                .then(
                    res => dispatch({
                        type : RETRIVE_SINGLE_TICKET_SUCCESS,
                        data : res.data
                    })
                );            
            }
        )
    }
}

export function submitNewTicket(val) {
    let config = { 
        headers: { token: window.localStorage.getItem('token')}
    }
    const dataToSubmit = {
        title : val.title,
        content : val.content,
        product : {
            name : val.product,
            serialNumber : val.serial
        }
    };
    return dispatch => {
        axios.post(`${API_URL}/tickets`, dataToSubmit, config)
        .then(
            res => dispatch({
                type : SUBMIT_TICKET_SUCCESS,
                data : res
            })
        );
    }
}

export function submitNewTicketComment(val, id) {
    let token = window.localStorage.getItem('token');
    let config = {
        headers: { token : token }
    }
    val = {content : val.content};
    return dispatch => {
        axios.post(`${API_URL}/tickets/${id}`, val, config)
        .then(
            res => dispatch({
                type : SUBMIT_TICKET_COMMENT_SUCCESS,
                data : res
            })
        );
    }
}

export function markTicketSolved(id) {
    let token = window.localStorage.getItem('token');
    let config = {
        headers : { token : token }
    }
    return dispatch => {
        axios.put(`${API_URL}/markTicketAsSolved/${id}`, {}, config)
        .then(
            res => dispatch({
                type : MARK_TICKET_SOLVED,
            })
        )
    }
}

