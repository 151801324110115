import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Button, TextField, Typography } from '@material-ui/core';

import Navbar from '../Components/Navbar';
import { forgotPassword } from '../redux/auth.redux';
import { mobile } from '../Utils/DeviceCheck';
import { withSnackbar } from 'notistack';

const styles = theme => ({
  paper: {
		margin: 'auto',
		width: '350px',
		padding: 30,
	},
  container: {
    display: 'block',
    textAlign: 'center',
    margin: 'auto',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  button: {
  	marginTop: 60,
	marginBottom: 60,
	paddingLeft: 60,
	paddingRight: 60,  
  },
  header: {
  	marginTop: 30,
  },
});

class ForgotPassword extends React.Component {
	static propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired,
	};
	constructor(props) {
		super(props);
		this.auth = {email: null};
		this.state = {err: null};
	}

	componentDidUpdate() {
		if (this.props.auth.emailSuccess) {
			this.props.history.push('/login');
			this.props.enqueueSnackbar('Reset Password Email Sent')
		} 
	}

	render() {
		const { classes } = this.props;
		function validateEmail (email) {
			const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return regexp.test(email);
		}

		const setEmail = (input) => {
			this.auth.email = input;
		}


		const submit = () => {
			if (!validateEmail(this.auth.email)) {
				this.setState({err: 'Plase enter an valid email address'});
				return;
			} else {
				this.setState({err: null});
			}
			
			this.props.forgotPassword(this.auth);
			
		}

		return (
			<div>
				<Navbar name="Navbar" companyName="Sonic Hits" websiteProp="Login" history={this.props.history}/>
				<span className={classes.paper}>
					<form  className={classes.container}>
						<Typography className={classes.header} variant='h5' color='primary' gutterBottom>Forgot your password?</Typography>
						<div>
							<Typography variant='body1' color='inherit' gutterBottom style={{width: mobile() ? '80%' : '30vw', paddingTop: 30, color: 'grey', margin: 'auto', textAlign: 'left'}}>Type in your email below to request a password reset. An email will be sent to you with instructions on how to proceed.</Typography>
						</div>
						<div>
							<TextField
								id="standard-email"
								label="Email"
								className={classes.textField}
								onChange={(event) => setEmail(event.target.value)}
								margin="normal"
								style ={{width: mobile() ? '80%' : '30vw'}}
							/>
						</div>
						<div><Button className={classes.button} variant="contained" size="medium" color="primary" onClick={()=>submit()}> Submit </Button></div>
						<div>
							<Typography color='primary' gutterBottom={true}><a href="/login">Return to login?</a></Typography>
						</div>
						<div>
							<Typography color='secondary' gutterBottom={true} style={{margin:40}}>{this.state.err}</Typography>
							<Typography color='secondary' gutterBottom={true} style={{margin:40}}>{this.props.auth.err}</Typography>
						</div>
					</form>
				</span>
			</div>
		);
	}
	
}

ForgotPassword.propTypes = {
	classes: PropTypes.object.isRequired,
}

const mapStatetoProps = (state) => {return { auth : state.auth, route : state.route }};
const actionCreators = { forgotPassword };
export default connect(mapStatetoProps, actionCreators)(withStyles(styles)(withSnackbar(ForgotPassword)));