import axios from 'axios';
import { API_URL } from '../Glogbal_Config';
// define action names
const INC_NUM_OF_NOTIF = 'INC_NUM_OF_NOTIF';
const DEC_NUM_OF_NOTIF = 'DEC_NUM_OF_NOTIF';
const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
const OPEN_DRAWER = 'OPEN_DRAWER';
const CLOSE_DRAWER = 'CLOSE_DRAWER';
const RETRIVE_SUGGESTIONS_SUCCECESS = 'RETRIVE_SUGGESTIONS_SUCCECESS';
const RETRIVE_NUMNOTIFS_SUCCECESS = 'RETRIVE_NUMNOTIFS_SUCCECESS';
const CLEAR_NUM_NOTIFS = 'CLEAR_NUM_NOTIFS';
const IN_UNREAD = 'IN_UNREAD';
const NOT_IN_UNREAD = 'NOT_IN_UNREAD';
// init state
const initNotifState = { numOfNotifications:0 };
const initDrawerState = { drawerOpen:false };
const initSuggestState = { tags : [] };
const initUnread = { unread : false };
// reducer for number of notifications
export function updateNumOfNotifications(state=initNotifState, action) {
    switch(action.type){
        case INC_NUM_OF_NOTIF:
            state.numOfNotifications += 1;
            return {...state};
        case DEC_NUM_OF_NOTIF:
            state.numOfNotifications -= 1;
            return {...state};
        case RETRIVE_NUMNOTIFS_SUCCECESS:
            state.numOfNotifications = (action.data) ? action.data.unreadUpdates.length : 0;
            return {...state};
        case CLEAR_NUM_NOTIFS:
            state.numOfNotifications = 0;
            return {...state};
        default:
            return state;
    }
}
// action creaters for notification
export function incNumOfNotification() {
    return {type : INC_NUM_OF_NOTIF};
}
export function decNumOfNotificaiton() {
    return {type : DEC_NUM_OF_NOTIF};
}
export function retrieveNumNotification() {
    const url = `${API_URL}/myUnreadUpdates`;
    let config = { 
        headers: { token: window.localStorage.getItem('token') }
    }
    return dispatch => {
        axios.get(url, config)
        .then((res)=>dispatch({
            type : RETRIVE_NUMNOTIFS_SUCCECESS,
            data : res.data
        }));
    }
}
export function clearNumNotifs() {
    return { type : CLEAR_NUM_NOTIFS };
}

export function updateDrawerEditorOpen(state=initDrawerState, action) {
    switch(action.type) {
        case TOGGLE_DRAWER:
            let drawerOpen = state.drawerOpen;
            if (drawerOpen !== undefined) state.drawerOpen = !drawerOpen;
            else state = {...state, drawerOpen:true};
            return {...state};
        case OPEN_DRAWER:
            return {...state, drawerOpen:true};
        case CLOSE_DRAWER:
            return {...state, drawerOpen:false};
        default:
            return state;
    }
}
// action creaters for toggleDrawer
export function toggleDrawer() {
    return {type : TOGGLE_DRAWER};
}
export function openDrawer() {
    return {type : OPEN_DRAWER};
}
export function closeDrawer() {
    return {type : CLOSE_DRAWER};
}

export function updateAutoSuggestion(state=initSuggestState, action) {
    switch(action.type) {
        case RETRIVE_SUGGESTIONS_SUCCECESS:
            state.tags = action.data.tags;
            return {...state};
        default:
            return state;
    }
}

export function retrieveSuggestions() {
    const url = `${API_URL}/posts/allTags`;
    return dispatch => {
        axios.get(url)
        .then( (res) => dispatch({
            type : RETRIVE_SUGGESTIONS_SUCCECESS,
            data : res.data
        }));
    };
}

export function updateUnread(state=initUnread, action) {
    switch(action.type) {
        case IN_UNREAD:
            state.unread = true;
            return { ...state };
        case NOT_IN_UNREAD:
            state.unread = false;
            return { ...state };
        default:
            return state;
    }
}

export function inUnread() {
    return { type : IN_UNREAD };
}

export function notInUnread() {
    return { type : NOT_IN_UNREAD };
}