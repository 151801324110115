import React from 'react';
import { connect } from 'react-redux';

import Divider from '@material-ui/core/Divider';

import Navbar from '../Components/Navbar';
import DummyPadding from '../Components/DummyPadding';
import TicketTable from '../Components/TicketTable';
import { retrieveTickets, retrieveAllTickets } from '../redux/ticket.redux';
import { clearEditorState } from '../redux/editor.redux';

class AdminConsole extends React.Component {
    retrieved = false;
	componentDidMount() {
		if (this.props.tickets.ticketTableData.length === 0)
			if(this.props.auth.role === 'super') this.props.retrieveAllTickets();
        this.props.clearEditorState();
	}

	componentDidUpdate() {
		if (this.props.newticket !== undefined) {
			this.props.history.push(`/ticket/${this.props.newticket}`);
		}
        this.props.clearEditorState();
        if (this.props.auth.role === 'super' && this.retrieved === false) {
            this.props.retrieveAllTickets();
            this.retrieved = true;
        } 
	}

	render() {
		return (
			<div>
				<Navbar name="Navbar" companyName="Sonic Hits" websiteProp="Admin" history={this.props.history}/>
				{/* <ForumTopBar isTicket={true}></ForumTopBar> */}
				<Divider/>
				<TicketTable tickets={this.props.tickets} history={this.props.history}/>
				{/* <DraftEditDrawer type="topic" isTicket={true}></DraftEditDrawer> */}
				<DummyPadding/>
			</div>
			
		)
	}
}

const mapStatetoProps = (state) => {return { tickets : state.ticket, newticket : state.editor.ticketId, auth : state.auth }};
const actionCreators = {retrieveTickets, clearEditorState, retrieveAllTickets};
export default connect(mapStatetoProps, actionCreators)(AdminConsole);