import React from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import Send from '@material-ui/icons/Send';

import { toggleDrawer, closeDrawer } from '../redux/anim.redux';
import { submitNewTopic, submitNewComment } from '../redux/post.redux';
import { submitNewTicket, submitNewTicketComment } from '../redux/ticket.redux';
import { titleInvalid, contentInvalid, serialInvalid, productInvalid, tagsInvalid, clearTitleError } from '../redux/editor.redux';

const styles = theme => ({
    leftIcon: {
      marginLeft : 0,
      marginRight: theme.spacing.unit,
    },
  });

class DrawerButtonList extends React.Component{
    submitTopicWraper = () => {
        if (this.props.isTicket === true) {
            let satisfied = true;
            if (this.props.editorContent.title.trim() === "") {
                this.props.titleInvalid("Ticket title can't be blank");
                satisfied = false;
            }
            if (this.props.editorContent.content.trim() === "") {
                this.props.contentInvalid("content can't be blank");
                satisfied = false;
            }
            if (this.props.editorContent.serial.trim() === "") {
                this.props.serialInvalid("Serial Number can't be blank");
                satisfied = false;
            }
            if (this.props.editorContent.product.trim() === "") {
                this.props.productInvalid("Product name can't be blank");
                satisfied = false;
            }
            if (this.props.editorContent.tags.length === 0) {
                this.props.tagsInvalid("Please spacify a tag");
                satisfied = false;
            }
            if (satisfied) {
                this.props.submitNewTicket(this.props.editorContent);
                this.props.clearTitleError();
                this.props.closeDrawer();
            }
        } else {
            // console.log(this.props.editorContent);
            let satisfied = true;
            if (this.props.editorContent.title.trim() === "") {
                this.props.titleInvalid("title can't be blank");
                satisfied = false;
            }
            if (this.props.editorContent.content.trim() === "") {
                this.props.contentInvalid("content can't be blank");
                satisfied = false;
            }
            if (this.props.editorContent.tags.length === 0) {
                this.props.tagsInvalid("please spacify a tag");
                satisfied = false;
            }
            if (satisfied) {
                this.props.submitNewTopic(this.props.editorContent);
                this.props.clearTitleError();
                this.props.closeDrawer();
            }
        }
    }
    submitCommentWraper = () => {
        (this.props.isTicket === true) ? this.props.submitNewTicketComment(this.props.editorContent, this.props.id) : this.props.submitNewComment(this.props.editorContent, this.props.id);
        this.props.closeDrawer();
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Grid container direction='row' justify="flex-start" alignItems="center" spacing={8}>
                    <Grid item xs="auto">
                        <Button color="secondary" onClick={this.props.type === 'comment' ? this.submitCommentWraper : this.submitTopicWraper}>
                            <Send className={classes.leftIcon}></Send>Submit
                        </Button>
                    </Grid>
                    <Grid item xs="auto">
                        <Button color="inherit" onClick={this.props.closeDrawer}>cancel</Button>
                    </Grid>
                </Grid>
            </div>
        );
    };
    
}
const mapStatetoProps = (state) => {return { drawer : state.drawer, editorContent : state.editor }};
const actionCreators = {toggleDrawer, closeDrawer, submitNewTopic, submitNewComment, submitNewTicket, submitNewTicketComment, titleInvalid, contentInvalid, serialInvalid, productInvalid, tagsInvalid, clearTitleError};
export default connect(mapStatetoProps, actionCreators)(withStyles(styles)(DrawerButtonList));