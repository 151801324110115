import React from 'react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {Avatar} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Badge from '@material-ui/core/Badge';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import { AVATAR_URL } from '../Glogbal_Config';
import { connect } from 'react-redux';
import { retrieveNumNotification, clearNumNotifs, inUnread } from '../redux/anim.redux';
import { logOut } from '../redux/auth.redux';
import { retrieveUnreadPost } from '../redux/post.redux';

class AccountBtn extends React.Component {
	static propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired,
	};
	
	state = {
		anchorEl: null,
	};

	handleProfileMenuOpen = event => {
		this.setState({ anchorEl:event.currentTarget });
		if (this.props.auth.isLoggedIn) this.props.retrieveNumNotification();
	};

	handleProfileMenuClose = () => {
		this.setState({ anchorEl: null });
	}

	handleMenuClose = () => {
		this.setState({ anchorEl: null });
	}

	handleMyAccount = () => {
		this.handleMenuClose();
		this.props.history.push('/myAccount')
	}

	handleMyTicket = () => {
		this.handleMenuClose();
		this.props.history.push('/ticketDashboard');
	}

	handleSignup = () => {
		this.handleMenuClose();
		this.props.history.push('/signup')
	}

	handleLogin = () => {
		this.handleMenuClose();
		this.props.history.push('/login')
	}
	handleLogout = () => {
		this.handleMenuClose();
		window.localStorage.removeItem('token');
		this.props.logOut();
		this.props.clearNumNotifs();
	}

	handleUnread = () => {
		// this.props.retrieveUnreadPost();
		// this.props.inUnread();
		this.props.history.push('/?state=unread');
		this.handleMenuClose();
	}

	handleBookmark = () => {
		// this.props.retrieveUnreadPost();
		// this.props.inUnread();
		this.props.history.push('/?state=bookmarks');
		this.handleMenuClose();
	}

	handleMyPosts = () => {
		// this.props.retrieveUnreadPost();
		// this.props.inUnread();
		this.props.history.push(`/?arthur=${this.props.auth.userId}`);
		this.handleMenuClose();
	}

	handleAdminDashboard = () => {
		// this.props.retrieveUnreadPost();
		// this.props.inUnread();
		this.props.history.push(`/admin`);
		this.handleMenuClose();
	}

	render() {
		const { anchorEl } = this.state;
		const isMenuOpen = Boolean(anchorEl);
		const renderUnLoggedMenu = (
			<Menu
				getContentAnchorEl = {null}
				anchorEl = {anchorEl}
				anchorOrigin = {{ vertical: 'bottom', horizontal: 'center' }}
				transformOrigin = {{ vertical: 'top', horizontal: 'center' }}
				open = {isMenuOpen}
				onClose = {this.handleMenuClose}
			>
				<MenuItem onClick={this.handleLogin}>Login</MenuItem>
				<Divider />
				<MenuItem onClick={this.handleSignup }>Signup</MenuItem>
			</Menu>
		);
		const renderLoggedInMenu = (
			<Menu
				getContentAnchorEl = {null}
				anchorEl = {anchorEl}
				anchorOrigin = {{ vertical: 'bottom', horizontal: 'center' }}
				transformOrigin = {{ vertical: 'top', horizontal: 'center' }}
				open = {isMenuOpen}
				onClose = {this.handleMenuClose}
			>
				<MenuItem onClick={this.handleUnread}>
					<Badge badgeContent={this.props.notif.numOfNotifications} color="secondary" invisible={this.props.notif.numOfNotifications > 0 ? false : true}>
						Unread Updates
					</Badge>
				</MenuItem>
				<MenuItem onClick={this.handleMyPosts}>My Topics</MenuItem>
				<MenuItem onClick={this.handleBookmark}>Followed Topics</MenuItem>
				<Divider />
				<MenuItem onClick={this.handleMyTicket}>Ticket Dashboard</MenuItem>
				<Divider />
				<MenuItem onClick={this.handleMyAccount}>My Account</MenuItem>
				{this.props.auth.role === 'super' || this.props.auth.role === 'admin' ? <MenuItem onClick={this.handleAdminDashboard}>Admin Dashboard</MenuItem> : null}
				<Divider />
				<MenuItem onClick={this.handleLogout }>Logout</MenuItem>
			</Menu>
		);
		return (
			<div className = {this.props.name}>
				<IconButton 
					aria-owns={isMenuOpen ? 'material-appbar' : undefined}
					aria-haspopup = "true"
					onClick={this.handleProfileMenuOpen}
					color="inherit"
				>
					<Badge badgeContent={this.props.notif.numOfNotifications !== undefined ? this.props.notif.numOfNotifications : 0} color="secondary" invisible={(this.props.notif.numOfNotifications > 0 && !Boolean(anchorEl))? false : true}>
						{this.props.auth.isLoggedIn && this.props.auth.avatar ? <Avatar key={new Date()} alt='userAvatar' src={AVATAR_URL+this.props.auth.avatar}/> : <AccountCircle />}
					</Badge>
				</IconButton>
				{this.props.auth.isLoggedIn ? renderLoggedInMenu : renderUnLoggedMenu}
			</div>
		)
	};
}

const mapStatetoProps = (state) => {return {auth : state.auth, notif : state.notif}};
const actionCreators = { logOut, retrieveNumNotification, clearNumNotifs, retrieveUnreadPost, inUnread };
export default connect(mapStatetoProps, actionCreators)(withRouter(AccountBtn));