import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar } from '@material-ui/core';

import AccountBtn from './AccountBtn'
import IconBtn from './IconBtn'
import { mobile } from '../Utils/DeviceCheck';

const styles = () => {
    let _subRoot = mobile() ? {marginLeft:0, marginRight:0} : {marginLeft:"15%", marginRight:"15%"};
	return ({
		root: {
			flexGrow: 1,
		},
		subRoot: _subRoot,
		grow: {
			  flexGrow: 1,
			  fontWeight: 100,
			  letterSpacing: '-1px',
			  fontSize: 21
		  },
		  companyNameStyle: {
			  fontWeight: 900,
			  letterSpacing: '-1px',
			  fontSize: 21,
		  },
	  });
} 

function NavBar(props) {
	return (
		<div className={props.classes.root}>
		<AppBar postion="sticky">
			<div className={props.classes.subRoot}>
				<Toolbar>
					<IconBtn name="iconBtn" history={props.history}/>
					<span className={props.classes.companyNameStyle} > {props.companyName} </span>
					<pre> </pre>
					<span className={props.classes.grow}> {props.websiteProp} </span>
					<AccountBtn name="accountBtn"/>
				</Toolbar>
			</div>
		</AppBar>
		</div>
	)
}

export default withStyles(styles)(NavBar);
