import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Button, TextField, Typography, InputAdornment } from '@material-ui/core';
import { RemoveRedEye } from '@material-ui/icons';

import Navbar from '../Components/Navbar';
import { logIn } from '../redux/auth.redux';

const styles = theme => ({
  paper: {
		margin: 'auto',
		width: '350px',
		padding: 30,
	},
  container: {
    display: 'block',
    textAlign: 'center',
    margin: 'auto',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  button: {
  	marginTop: 30,
  	marginBottom: 30,
  },
  header: {
  	marginTop: 30,
  },
});

class Login extends React.Component {
	static propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired,
	};
	constructor(props) {
		super(props);
		this.auth = {email: null, password: null};
		this.state = {err: null, showPassword: false};
	}

	componentDidUpdate() {
		if (this.props.auth.isLoggedIn) {
			const routeArr = this.props.route.lastPath ? this.props.route.lastPath.split('/') : null;
			if (routeArr === null || routeArr[1] !== 'post' ) {
				this.props.history.push('/');
			} else {
				this.props.history.push(this.props.route.lastPath);
			}
		} 
	}


	render() {
		const { classes } = this.props;
		function validateEmail (email) {
			const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return regexp.test(email);
		}
		function validatePassword (password) {
			const regexp = /^(?=.*[A-Za-z])(?=.*[0-9])(?=.{8,})/
			return regexp.test(password);
		}
		const setEmail = (input) => {
			this.auth.email = input;
		}
		const setPassword = (input) => {
			this.auth.password = input;
		}

		const handleKeyPress = (e) => {
			if (e.key === 'Enter') {
				login()
			}
		}

		const login = () => {
			if (!validateEmail(this.auth.email)) {
				this.setState({err: 'Plase enter an valid email address'});
				return;
			} else if (!validatePassword(this.auth.password)) {
				this.setState({err: 'Wrong Password, it must have more than 8 characters, contain at least 1 letter and 1 digit.'});
				return;
			}  else {
				this.setState({err: null});
			}
			
			this.props.logIn(this.auth);
			
		}

		const handlePasswordMask = () => {
			this.setState((state) => ({showPassword: !state.showPassword}));
		}

		return (
			<div>
				<Navbar name="Navbar" companyName="Sonic Hits" websiteProp="Login" history={this.props.history}/>
				<span className={classes.paper}>
					<form  className={classes.container}>
						<Typography className={classes.header} variant='h5' color='primary'>Welcome Back!</Typography>
						<div>
							<TextField
								id="standard-email"
								label="Email"
								className={classes.textField}
								onChange={(event) => setEmail(event.target.value)}
								margin="normal"
								onKeyPress={(event) => handleKeyPress(event)}
							/>
						</div>
						<div>
							<TextField
								id="standard-password-input"
								label="Password"
								className={classes.textField}
								type={this.state.showPassword ? 'text' : 'password'}
								autoComplete="current-password"
								margin="normal"
								onChange={(event) => setPassword(event.target.value)}
								onKeyPress={(event) => handleKeyPress(event)}
								InputProps={{
									endAdornment: (
									  <InputAdornment position="end">
										<RemoveRedEye onClick={handlePasswordMask}/>
									  </InputAdornment>
									),
								}}
							/>
						</div>
						<div>
							<Button className={classes.button} variant="contained" size="medium" color="primary" onClick={()=>login()}> Login </Button>
						</div>
						<div>
							<Typography color='primary' gutterBottom={true}><a href="/forgotPassword">Forgot password?</a></Typography>
						</div>
						<div>
							<Typography color='primary' gutterBottom={true}><a href="/signup">Do not have an account?</a></Typography>
						</div>
						<div>
							<Typography color='secondary' gutterBottom={true} style={{margin:40}}>{this.state.err}</Typography>
							<Typography color='secondary' gutterBottom={true} style={{margin:40}}>{this.props.auth.err}</Typography>
						</div>
					</form>
				</span>
			</div>
		);
	}
	
}

Login.propTypes = {
	classes: PropTypes.object.isRequired,
}

const mapStatetoProps = (state) => {return { auth : state.auth, route : state.route }};
const actionCreators = { logIn };
export default connect(mapStatetoProps, actionCreators)(withStyles(styles)(Login));