import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, CircularProgress, Grid, Typography, Divider, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import {Done, HourglassEmpty, ExpandMore} from '@material-ui/icons';

import Post from './Post';
import TicketBoxMobile from './TicketBoxMobile';

import { durationToCurrent } from '../Utils/DateTimeCal';
import { mobile } from '../Utils/DeviceCheck';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    // overflowX: 'auto',
  },
  table: {
    maxWidth: "100%",
  },
  header: {
      margin: theme.spacing.unit
  }
});

function TicketTable(props) {
    const { classes } = props;
    let inProgressTableBody = null;
    let solvedTableBody = null;
    let solved = [];
    let inProgress = [];
    let dummyTableCell = [];
    const numSpan = 6;
    for (let i = 0; i < numSpan; i++) dummyTableCell.push(<TableCell key={i}/>)
    if (props.tickets === undefined || props.tickets.ticketTableData === undefined ) {
        inProgressTableBody = <CircularProgress/>;
        solvedTableBody = <CircularProgress/>;
    } else {
        for (let i in props.tickets.ticketTableData) {
            const ticket = props.tickets.ticketTableData[i];
            ticket.isSolved ? solved.push(ticket) : inProgress.push(ticket)
        }
        inProgressTableBody = inProgress.map(ticket => {
            let recent = durationToCurrent(ticket.lastUpdated);
            return (
                <TableRow key={ticket._id}>
                    <TableCell align="left" colSpan={numSpan+1}>
                        <Post title={ticket.title} id={ticket._id} isTicket={true} isSolved={ticket.isSolved} history={props.history}/>
                    </TableCell>
                    <TableCell align="right" >{ticket.isSolved ? <Done style={{color:'green', margin: 4}}/> : <HourglassEmpty style={{color:'DarkGoldenRod ', margin: 4}}/>}</TableCell>
                    <TableCell align="right" >{`${recent.duration} ${recent.time}`}</TableCell>
                </TableRow>
            );
        });
        solvedTableBody = solved.map(ticket => {
            let recent = durationToCurrent(ticket.lastUpdated);
            return (
                <TableRow key={ticket._id}>
                    <TableCell align="left" colSpan={numSpan+1}>
                        <Post title={ticket.title} id={ticket._id} isTicket={true} isSolved={ticket.isSolved} history={props.history}/>
                    </TableCell>
                    <TableCell align="right" >{ticket.isSolved ? <Done style={{color:'green', margin: 4}}/> : <HourglassEmpty style={{color:'DarkGoldenRod ', margin: 4}}/>}</TableCell>
                    <TableCell align="right" >{`${recent.duration} ${recent.time}`}</TableCell>
                </TableRow>
            );
        });
    }

    const nonMobileVer = (
        <div className={classes.root} >
            <h2>My Tickets</h2>
             <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary expandIcon={<ExpandMore />}>In Progress</ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Table className={classes.table} >
                    <TableHead>
                    <TableRow>
                        <TableCell align="left" >Topics</TableCell>
                        {dummyTableCell}
                        <TableCell align="right" >Status</TableCell>
                        <TableCell align="right" >Activity</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {inProgressTableBody}
                    </TableBody>
                </Table>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMore />}>Solved</ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Table className={classes.table} >
                    <TableHead>
                    <TableRow>
                        <TableCell align="left" >Topics</TableCell>
                        {dummyTableCell}
                        <TableCell align="right" >Status</TableCell>
                        <TableCell align="right" >Activity</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {solvedTableBody}
                    </TableBody>
                </Table>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
    let mobileVer = null;
    if (props.tickets === undefined || props.tickets.ticketTableData === undefined ) {
        mobileVer = <CircularProgress/>;
    } else {
        mobileVer = (
                    <div className={classes.root}>
                            <h2> My Tickets </h2>
                            <ExpansionPanel defaultExpanded>
                            <ExpansionPanelSummary expandIcon={<ExpandMore />}>In Progress</ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{flexDirection: 'column'}}>
                            <div className={classes.header}>
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item><Typography variant="body2" color="textSecondary">{"Ticket"}</Typography></Grid>
                                <Grid item><Typography variant="body2" color="textSecondary">{"LastUpdated"}</Typography></Grid>
                            </Grid>
                            <Divider />
                            </div>
                                {inProgress.map(ticket => {
                                    let recent = durationToCurrent(ticket.lastUpdated);
                                    return (
                                        <TicketBoxMobile key={ticket._id} history={props.history} id={ticket._id} time={recent} title={ticket.title} isSolved={ticket.isSolved}/>
                                    );
                                })}
                            </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<ExpandMore />}>Solved</ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{flexDirection: 'column'}}>
                            <div className={classes.header}>
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item><Typography variant="body2" color="textSecondary">{"Ticket"}</Typography></Grid>
                                <Grid item><Typography variant="body2" color="textSecondary">{"LastUpdated"}</Typography></Grid>
                            </Grid>
                            <Divider />
                            </div>
                                {solved.map(ticket => {
                                    let recent = durationToCurrent(ticket.lastUpdated);
                                    return (
                                        <TicketBoxMobile key={ticket._id} history={props.history} id={ticket._id} time={recent} title={ticket.title} isSolved={ticket.isSolved}/>
                                    );
                                })}
                            </ExpansionPanelDetails>
                            </ExpansionPanel>
                    </div>
                    );
    }
    const res = mobile() ? mobileVer : nonMobileVer;
    return res;
}

TicketTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TicketTable);