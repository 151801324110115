import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import thunk from 'redux-thunk';
import { applyMiddleware, compose, createStore } from 'redux';
import { Provider } from 'react-redux';
import reducers from './reducer';  
import createRootReducer from './reducer';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles'

const store = createStore(
    createRootReducer(reducers), 
    compose(
        applyMiddleware(thunk),
        window.__REDUX_DEVTOOLS_EXTENSION__?window.__REDUX_DEVTOOLS_EXTENSION__():f=>f
    )
);

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
        fontFamily: [
            'Gill Sans',
            'Gill Sans MT',
            'Calibri',
            'Trebuchet MS',
            'sans-serif',
        ].join(','),
        fontSize: 15,
    },
})

ReactDOM.render(
    (
    <MuiThemeProvider theme={theme}>
        <Provider store={store}>
            <App />
        </Provider>
    </MuiThemeProvider>
    ),
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
