import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import { Divider, Grid, CircularProgress } from '@material-ui/core';

import Post from '../Components/Post';
import CommentBox from '../Components/CommentBox';
import DraftEditDrawer from '../Components/DraftEditDrawer';
import DetailFooterList from '../Components/DetailFooterList';
import Navbar from '../Components/Navbar';

import { retrieveSinglePost, clearPostState } from '../redux/post.redux';
import { clearEditorState } from '../redux/editor.redux';
import { updateLastPath } from '../redux/route.redux';

import { mobile } from '../Utils/DeviceCheck';
import { withSnackbar } from 'notistack';

const styles = theme => ({
    root : {
        padding: theme.spacing.unit * 2,
    },
    post : {
        marginBottom: theme.spacing.unit * 1,
    },
    footer : {
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white',
        zIndex: 999,
    },
    content : {
        transition: theme.transitions.create(['margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        marginBottom: 0,
    },
    contentShift : {
        transition: theme.transitions.create(['margin'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
        }),
        marginBottom: "50%",
    },
})

class PostDetail extends React.Component {
    state = {following: null}
	componentDidMount() {
        this.props.updateLastPath(this.props.location.pathname);
        this.props.retrieveSinglePost(this.props.match.params.postid);
        this.props.clearEditorState();
    }
    componentDidUpdate() {
        if (this.props.newComment !== undefined) {
            this.props.clearEditorState();
            this.props.retrieveSinglePost(this.props.match.params.postid);
        }
        if (this.props.posts.success !== undefined) {
            if (this.props.posts.success === 'follow') {
                this.setState(state => ({...state, following: true}))
                this.props.enqueueSnackbar('Successfully subscribed.');
            } else {
                this.setState(state => ({...state, following: false}))
                this.props.enqueueSnackbar('Successfully unsubscribed.');
            }
            this.props.clearPostState();
        }
        if (this.props.posts.postDetailData.followers && this.state.following === null) {
            const followers = this.props.posts.postDetailData.followers.map(follower => follower._id)
            const following = followers.includes(this.props.userId)
            this.setState(state=> ({...state, following: following}))
        }
    }
	render() {
        const { classes } = this.props;
        const { posts } = this.props;
        const isMobile = mobile();
        const postComponent = (posts.postDetailData !== undefined) ? <Post title={posts.postDetailData.title} tags={posts.postDetailData.tags} id={posts.postDetailData._id} history={this.props.history}/> : <CircularProgress/>;
        const topicComponent = (posts.postDetailData !== undefined) ? <CommentBox isTopic={true} arthur={posts.postDetailData.arthur} content={posts.postDetailData.content} time={posts.postDetailData.lastUpdated} isMobile={isMobile} enqueue={this.props.enqueueSnackbar} history={this.props.history}></CommentBox> : <CircularProgress/>;
        const commentComponent = (posts.postDetailData.comments !== undefined) ? posts.postDetailData.comments.map((val,i) => (<CommentBox isTopic={false} userId={this.props.userId} postId={posts.postDetailData._id} commentId={val._id} key={val.timestamp} arthur={val.arthur} content={val.content} index={i+1} time={val.timestamp} isMobile={isMobile} enqueue={this.props.enqueueSnackbar} history={this.props.history}/>)) : <CircularProgress/>;
        const contentWidth = (isMobile) ? 12 : 10;
        const stepper = (isMobile) ? null : (<Grid item xs={2}></Grid>)
        return (
			<div className={classes.root}>
                <Navbar name="Navbar" companyName="Sonic Hits" websiteProp="Forum" history={this.props.history}/>
                <div className={classNames(classes.content, {
                    [classes.contentShift]: this.props.drawer.drawerOpen,
                })}>
                    <Grid container spacing={8}>
                        <Grid item xs={contentWidth}>
                            <div className={classes.post}>
                                {postComponent}
                            </div> 
                            <Divider />
                            <div>
                                { topicComponent }
                                { commentComponent }
                            </div>
                            <div className={classes.footer}>
                                <DetailFooterList isTicket={false} justify="flex-start" color="secondary" id={this.props.posts.postDetailData._id} isArthur={this.props.userId !== undefined && posts.postDetailData.arthur !== undefined ? this.props.userId === posts.postDetailData.arthur._id : null} following={this.state.following} history={this.props.history} enqueue={this.props.enqueueSnackbar}></DetailFooterList>
                            </div>
                        </Grid>
                        {stepper}
                    </Grid>
                </div>
                <div>
                    <DraftEditDrawer isMobile={isMobile} type="comment" title={this.props.posts.postDetailData.title} id={this.props.posts.postDetailData._id}></DraftEditDrawer>
                </div>
			</div>
		)
	}
}

const mapStatetoProps = (state) => {return { userId : state.auth.userId, posts : state.posts, drawer : state.drawer, newComment : state.editor.postId}};
const actionCreators = {retrieveSinglePost, clearEditorState, clearPostState, updateLastPath};
export default connect(mapStatetoProps, actionCreators)(withStyles(styles)(withSnackbar(PostDetail)));