import React from 'react';
import { connect } from 'react-redux';
import {Popover, Card, CardActionArea, CardMedia, Typography, Button} from '@material-ui/core/';
import {Person} from '@material-ui/icons'
import {withStyles} from '@material-ui/core/styles'
import { retrieveUserInfo } from '../redux/user.redux';
import {AVATAR_URL} from '../Glogbal_Config'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const styles = theme => ({
    card: {
        maxWidth: 345,
        background: theme.palette.default
    },
    media: {
        opacity: 0.4,
        background: 'linear-gradient(transparent, gray)',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'fixed',
        backgroundSize: 'cover',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    paragraph: {
        margin: theme.spacing.unit * 2
    },

})

class UserInfo extends React.Component {
    componentDidUpdate() {
        if (this.props.userId && this.props.userId !== this.props.userInfo._id && this.props.open) {
            this.props.retrieveUserInfo(this.props.userId)
        }
        
    }

    routeToArthur = (arthur) => {
        this.props.history.push(`/?arthur=${arthur}`)
    }

    render() {
        const userInfo = this.props.userInfo
        const {classes} = this.props
        const getBadgeColor = () => {
            switch (userInfo.role) {
                case 'super':
                    return 'orange';
                case 'admin': 
                    return 'red';
                default:
                    return 'grey'; 
            }
        }
        const dateJoined = new Date(userInfo.date_joined)
        const socialLinks = userInfo.socialLinks ? userInfo.socialLinks.map((link,i)=>(
            <Button key={i} color="primary" size="small" style={{maxWidth: '40px', maxHeight: '40px', minWidth: '40px', minHeight: '40px'}} onClick={()=>window.open(link.url, '_blank')}><FontAwesomeIcon key={`${i}_icon`} size='lg' icon={link.name in library.definitions.fab ? ['fab',link.name] : ['fas','link']}/></Button>
        )) : null
        return (
            <div>
                <Popover 
                    id='userInfo-popover' 
                    open={this.props.open} anchorEl={this.props.anchorEl}
                    onClose = {this.props.onClose}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    transformOrigin={{vertical: 'top', horizontal: 'center'}}
                >
                    <Card className = {classes.card}>
                        {userInfo.avatar ? <CardMedia className={classes.media} image={AVATAR_URL+userInfo.avatar} title={userInfo.username}/> : null}
                        <CardActionArea onClick={() => this.routeToArthur(userInfo._id)}>
                            <Typography component='p' className={classes.paragraph}>
                                {userInfo.username} <Person style={{color: getBadgeColor()}}/><br/>
                                {`Member Since ${dateJoined.getMonth()+1}/${dateJoined.getDate()}/${dateJoined.getFullYear()}`}<br/>
                                <br/>{userInfo.region} {userInfo.country} <br/>
                            </Typography>
                            <Typography component='p' className={classes.paragraph}>
                                {userInfo.bio}
                            </Typography>
                            <Typography component='p' className={classes.paragraph}>
                                {`${userInfo.posts ? userInfo.posts.length: null} Posts`}
                            </Typography>
                        </CardActionArea>
                        <div>
                            {socialLinks}
                        </div>
                    </Card>
                </Popover>
            </div>
        )
    }
}

const mapStatetoProps = (state) => {return { userInfo : state.userInfo}};
const actionCreators = { retrieveUserInfo };

export default connect(mapStatetoProps, actionCreators)(withStyles(styles)(UserInfo));