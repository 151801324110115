import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Grid, Button, Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { retrievePostsByTag } from '../redux/post.redux';
import {Star as StarIcon} from '@material-ui/icons';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
	},
	tag: {
		marginBottom: theme.spacing.unit / 4,
	},
	title: {
		textDecoration: 'none',
		fontSize: 18,
		fontWeight: 500,
		color: 'inherit',
		'&:hover': {
			color: 'orange'
		}
	},
});

const TagButton = withStyles({
	root: {
	  borderStyle: 'solid',
	  borderWidth: '1px',
	  borderColor: 'Grey',
	  borderRadius: 4,
	  border: 0,
	  color: '#343a40',
	  padding: '0px 8px',
	  minWidth: '10px',
	},
	label: {
	  textTransform: 'capitalize',
	  fontSize: 12,
	  fontWeight: 400,
	},
  })(Button);


function Post(props) {
	const {classes} = props;
	const tags = (props.tags !== undefined) ? props.tags.map(tag=>{
		return (
			<Grid item xs="auto" key={tag} ><TagButton key={tag} className = {classes.tags} onClick={()=>{props.history.push(`/?tags=${tag}`)}} variant="outlined" size="small" color="inherit"> {tag} </TagButton></Grid>
		);
	}): '' ;
	const product = (props.product) ?
			<Grid item xs="auto" ><TagButton className = {classes.tags} variant="outlined" size="small" color="inherit"> {props.product.name} {props.product.serialNumber} </TagButton></Grid> 
		 : ''
	const routeToDetail = () => {
		props.isTicket ? props.history.push(`/ticket/${props.id}`) : props.history.push(`/post/${props.id}`);
	}
	return (
		<div>
			<Grid container direction="column" justify="center" alignItems="flex-start" spacing={8}>
				<Grid item>
					<a onClick={routeToDetail} href='javascript:;' className={classes.title}>
						{props.sticky ? <StarIcon color='primary'/> : null}
						{props.title ? props.title : ""}
					</a>
				</Grid>
				<Grid item>
					<Grid container justify="flex-start" alignItems="center" className = {classes.tag} spacing={8} >
						{tags}
					</Grid>
				</Grid>
				<Grid item>
					<Grid container justify="flex-start" alignItems="center" className = {classes.tag} spacing={8} >
						{product}
					</Grid>
				</Grid>
			</Grid>
		</div>
	)
}

Post.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStatetoProps = () => ({});
const actionCreators = { retrievePostsByTag };
export default connect(mapStatetoProps, actionCreators)(withStyles(styles)(Post));