import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, IconButton, Tooltip } from '@material-ui/core';
import { Share, Bookmark, Reply, AssignmentTurnedIn, Delete } from '@material-ui/icons';

import { openDrawer, notInUnread } from '../redux/anim.redux';
import { subscribeTopic, unsubscribeTopic, deleteTopic, retrievePosts, clearPostTable } from '../redux/post.redux';
import { markTicketSolved } from '../redux/ticket.redux';
import { NUM_POSTS_PER_BATCH } from '../Glogbal_Config'

import Clipboard from 'react-clipboard.js';

const styles = theme => ({
    leftIcon: {
      marginLeft : 0,
      marginRight: theme.spacing.unit,
    },
  });

class DetailFooterList extends React.Component{
    replyClickHandler = () => {
        if (this.props.auth.isLoggedIn) {
            this.props.openDrawer();
        } else {
            this.props.history.push('/login');
        } 
    }

    subscribeClickHandler = () => {
        if (this.props.auth.isLoggedIn) {
            if (this.props.following) {
                this.props.unsubscribeTopic(this.props.id);
            } else {
                this.props.subscribeTopic(this.props.id);
            }
        } else {
            this.props.history.push('/login');
        }
    }

    shareClickHandler = () => {
        this.props.enqueue("post url has copied to clipboard");
    }

    markClickHandler = () => {
        this.props.markTicketSolved(this.props.id);
        this.props.enqueue("Ticket has been marked solved");
    }

    deletePost = () => {
        if (window.confirm('Sure to delete this post?')) {
            this.props.deleteTopic(this.props.id)
            this.props.clearPostTable();
            this.props.history.push('/');
        }
    }
    
    render() {
        const { classes } = this.props;
        return (
            <div>
                <Grid container justify={this.props.justify} alignItems="center" spacing={8}>
                    <Grid item xs="auto">
                        <Tooltip title={this.props.following ? 'unsubscribe' : 'subscribe'} placement="bottom"><IconButton onClick={this.subscribeClickHandler} color={this.props.following ? 'secondary' : 'default'}><Bookmark/></IconButton></Tooltip>
                    </Grid>
                    <Grid item xs="auto">
                        <Tooltip title="share" placement="bottom"><Clipboard component="a" data-clipboard-text={window.location.href} onSuccess={this.shareClickHandler}><IconButton><Share/></IconButton></Clipboard></Tooltip>
                    </Grid>
                    {this.props.auth.role === 'super' && this.props.isTicket ? <Grid item xs="auto">
                        <Button color={this.props.color} onClick={this.markClickHandler}><AssignmentTurnedIn className={classes.leftIcon} /> MarkSolved</Button>
                    </Grid> : null}
                    {(this.props.auth.role === 'super' || this.props.auth.role === 'admin' || this.props.isArthur) && !this.props.isTicket ? <Grid item xs="auto">
                        <Tooltip title="delete" placement="bottom"><IconButton onClick={this.deletePost}><Delete/></IconButton></Tooltip>
                    </Grid> : null}
                    <Grid item xs="auto">
                        <Button onClick={this.replyClickHandler}><Reply className={classes.leftIcon}></Reply> Reply</Button>
                    </Grid>
                </Grid>
            </div>
        );
    };
}
const mapStatetoProps = (state) => {return { drawer : state.drawer, auth : state.auth }};
const actionCreators = {openDrawer, subscribeTopic, unsubscribeTopic, markTicketSolved, deleteTopic, notInUnread, clearPostTable, retrievePosts};
export default connect(mapStatetoProps, actionCreators)(withStyles(styles)(DetailFooterList));