import { combineReducers } from 'redux';
import { auth } from './redux/auth.redux';
import { updateNumOfNotifications as notif, updateDrawerEditorOpen as drawer, updateAutoSuggestion as autoSuggest, updateUnread as unread } from './redux/anim.redux';
import { updatePostContent as posts } from './redux/post.redux'
import { updateTicketContent as ticket } from './redux/ticket.redux';
import { updateEditorContent as editor } from './redux/editor.redux';
import { updateMyAccount as myAccount, getUserInfo as userInfo } from './redux/user.redux';
import { updateRoute as route } from './redux/route.redux';

export default () => combineReducers({auth, notif, drawer, posts, ticket, editor, autoSuggest, myAccount, route, userInfo, unread});
