import React from 'react';
import { connect } from 'react-redux';
import { retrieveMyAccount, changeMyAvatar, changeMyAccount } from '../redux/user.redux';
import MyAcountTable from '../Components/MyAccountTable';
import Navbar from '../Components/Navbar';

class MyAccount extends React.Component {
	componentDidMount() {
		this.props.retrieveMyAccount();
	}
	render() {
		return (
			<div>
				<Navbar name="Navbar" companyName="Sonic Hits" websiteProp="MyAccount" history={this.props.history}/>
				<h1> My Account </h1>
				<MyAcountTable myAccount={this.props.myAccount} updateAvatar={this.props.changeMyAvatar} dispatch={this.props.dispatch} updateAccount={this.props.changeMyAccount}/>
			</div>
	
		)
	}
}

const mapStatetoProps = (state) => {return { myAccount : state.myAccount}};
const actionCreators = {retrieveMyAccount, changeMyAvatar, changeMyAccount}
export default connect(mapStatetoProps, actionCreators)(MyAccount);