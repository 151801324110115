const UPDATE_TITLE = 'UPDATE_TITLE';
const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
const UPDATE_SERIAL = 'UPDATE_SERIAL';
const UPDATE_TAGS = 'UPDATE_TAGS';
const UPDATE_CONTNET = 'UPDATE_CONTNET';
const SUBMIT_TOPIC_SUCCESS = 'SUBMIT_TOPIC_SUCCESS';
const SUBMIT_COMMENT_SUCCESS = 'SUBMIT_COMMENT_SUCCESS';
const SUBMIT_TICKET_SUCCESS = 'SUBMIT_TICKET_SUCCESS';
const SUBMIT_TICKET_COMMENT_SUCCESS = 'SUBMIT_TICKET_COMMENT_SUCCESS';
const CLEAR_EDITOR_STATE = 'CLEAR_EDITOR_STATE';
const TITLE_INVALID = 'TITLE_INVALID';
const CONTENT_INVALID = 'CONTENT_INVALID';
const CLEAR_TITLE_ERROR = 'CLEAR_TITLE_ERROR';
const PRODUCT_INVALID = 'PRODUCT_INVALID';
const SERIAL_INVALID = 'SERIAL_INVALID';
const TAGS_INVALID = 'TAGS_INVALID';
const UPDATE_STIKCY = 'UPDATE_STICKY'
const initNewEditorState = { title : "", tags : [], content : "", product : "", serial : "", titleError : false, titleErrorMsg : null, contentError : false, contentErrorMsg : null, serialError : false, serialErrorMsg : null, productError : false, productErrorMsg : null, tagError : false, tagErrorMsg : null, sticky: false };

export function updateEditorContent(state=initNewEditorState, action) {
    let data = null;
    switch(action.type) {
        case UPDATE_TITLE:
            data = action.data;
            state.title = data;
            return {...state};
        case UPDATE_PRODUCT:
            state.product = action.data;
            return {...state};
        case UPDATE_SERIAL:
            state.serial = action.data;
            return {...state};
        case UPDATE_TAGS:
            state.tags = action.data;
            return {...state};
        case UPDATE_CONTNET:
            state.content = action.data;
            return {...state};
        case SUBMIT_TOPIC_SUCCESS:
            state.postId = action.data.data._id;
            return {...state};
        case SUBMIT_COMMENT_SUCCESS:
            state.postId = action.data.data._id;
            return {...state};
        case SUBMIT_TICKET_SUCCESS:
            state.ticketId = action.data.data._id;
            return {...state};
        case SUBMIT_TICKET_COMMENT_SUCCESS:
            state.ticketId = action.data.data._id;
            return {...state};
        case TITLE_INVALID:
            state.titleError = true;
            state.titleErrorMsg = action.data;
            return {...state};
        case CLEAR_TITLE_ERROR:
            state.titleError = false;
            state.titleErrorMsg = null;
            return {...state};
        case CONTENT_INVALID:
            state.contentError = true;
            state.contentErrorMsg = action.data;
            return {...state};
        case PRODUCT_INVALID:
            state.productError = false;
            state.productErrorMsg = action.data;
            return {...state};
        case SERIAL_INVALID:
            state.serialError = false;
            state.serialErrorMsg = action.data;
            return {...state};
        case TAGS_INVALID:
            state.tagError = true;
            state.tagErrorMsg = action.data;
            return {...state};
        case CLEAR_EDITOR_STATE:
            state.postId = undefined;
            state.ticketId = undefined;
            state.title = "";
            state.tags = [];
            state.content = "";
            state.product = "";
            state.serial ="";
            state.titleError = false;
            state.titleErrorMsg = null;
            state.contentError = false;
            state.contentErrorMsg = null;
            state.tagError = false;
            state.tagErrorMsg = null
            return {...state};
        case UPDATE_STIKCY:
            state.sticky = action.data;
            return {...state};
        default:
            return state;
    }
}

export function updateTitle(val) {
    return {
        type : UPDATE_TITLE,
        data : val
    }
}
export function updateTags(val) {
    return {
        type : UPDATE_TAGS,
        data : val
    }
}
export function updateProduct(val) {
    return {
        type : UPDATE_PRODUCT,
        data : val
    }
}
export function updateSerial(val) {
    return {
        type : UPDATE_SERIAL,
        data : val
    }
}
export function updateContent(val) {
    return {
        type : UPDATE_CONTNET,
        data : val
    }
}

export function updateSticky(val) {
    return {
        type: UPDATE_STIKCY,
        data: val
    }
}
export function titleInvalid(val) {
    return {
        type : TITLE_INVALID,
        data : val
    }
}
export function contentInvalid(val) {
    return {
        type : CONTENT_INVALID,
        data : val
    }
}
export function productInvalid(val) {
    return {
        type : PRODUCT_INVALID,
        data : val
    }
}
export function serialInvalid(val) {
    return {
        type : SERIAL_INVALID,
        data : val
    }
}
export function tagsInvalid(val) {
    return {
        type : TAGS_INVALID,
        data : val
    }
}

export function clearEditorState() {
    return { type : CLEAR_EDITOR_STATE };
}
export function clearTitleError() {
    return { type : CLEAR_TITLE_ERROR };
}