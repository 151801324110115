import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import logo from '../assets/logo.svg';
import { retrievePosts, clearPostTable } from '../redux/post.redux';
import { notInUnread } from '../redux/anim.redux';
import { NUM_POSTS_PER_BATCH } from '../Glogbal_Config'

const clickHandler = (props) => () => {
	// if (props.history.location.pathname==='/') {
	// 	props.clearPostTable();
	// 	props.retrievePosts(0, 7, 'post');
	// } else {
	// 	props.history.push('/');
	// }
	props.clearPostTable();
	props.retrievePosts(0, NUM_POSTS_PER_BATCH, 'post');
	props.history.push('/');
	props.notInUnread();
}

function IconBtn(props) {
	return (
		<div>
			<Button onClick={clickHandler(props)}>
				<img style={{height:50, paddingLeft:3, paddingRight:3, paddingTop:3}} src={logo} alt="logo"/>
			</Button>
		</div>
	)
}

const mapStatetoProps = () => ({});
const actionCreators = { retrievePosts, clearPostTable, notInUnread };

export default connect(mapStatetoProps, actionCreators)(IconBtn);