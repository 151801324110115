import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableHead, TableRow, CircularProgress } from '@material-ui/core';

import Post from './Post';
import PostTopicBoxMobile from './PostTopicBoxMobile';

import { durationToCurrent } from '../Utils/DateTimeCal';
import { mobile } from '../Utils/DeviceCheck';

import { inherits } from 'util';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    maxWidth: "100%",
  },

  tableHead: {
      backgroundColor: theme.palette.background.primary,
  },

  arthur: {
      textDecoration: 'none',
      color: 'inherit',
      '&:hover': {
          color: 'orange'
      }
  }
});

function PostTable(props) {
    const { classes } = props;
    let tableBody = null;
    let dummyTableCell = [];
    const numSpan = 6;
    const routeToArthur = (arthur) => {
        props.history.push(`?arthur=${arthur}`)
    }
    for (let i = 0; i < numSpan; i++) dummyTableCell.push(<TableCell key={i}/>)
    if (props.posts === undefined || props.posts.postTableData === undefined ) {
        tableBody = <CircularProgress/>;
    } else {
        tableBody = props.posts.postTableData.map((post,i) => {
            let recent = durationToCurrent(post.lastUpdated);
            return (
                <TableRow key={post._id} className={classes.row} style={post.sticky ? {background: 'WhiteSmoke'} : null}>
                    <TableCell align="left" colSpan={numSpan+1}>
                        <Post history={props.history} title={post.title} tags={post.tags} id={post._id} sticky={post.sticky}/>
                    </TableCell>
                    <TableCell align="right" ><a href='javascript:;' onClick={() => routeToArthur(post.arthur._id)} className={classes.arthur}>{post.arthur.username}</a></TableCell>
                    <TableCell align="right" >{post.n_comments}</TableCell>
                    <TableCell align="right" >{`${recent.duration} ${recent.time}`}</TableCell>
                </TableRow>
            );
        });
    }

    const nonMobileVer = (
        <span className={classes.root} >
            <Table className={classes.table} >
                <TableHead className={classes.tableHead}>
                <TableRow>
                    <TableCell align="left" >Topics</TableCell>
                    {dummyTableCell}
                    <TableCell align="right" >Author</TableCell>
                    <TableCell align="right" >Replies</TableCell>
                    <TableCell align="right" >Activity</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {tableBody}
                </TableBody>
            </Table>
        </span>
    );
    let mobileVer = null;
    if (props.posts === undefined || props.posts.postTableData === undefined ) {
        mobileVer = <CircularProgress/>;
    } else {
        mobileVer = props.posts.postTableData.map((post, i) => {
                        let recent = durationToCurrent(post.lastUpdated);
                        return (
                            <PostTopicBoxMobile className={classes.row} key={post._id} history={props.history} n_comments={post.n_comments} title={post.title} tags={post.tags} id={post._id} arthur={post.arthur} sticky={post.sticky} time={`${recent.duration} ${recent.time}`}/>
                        );
                    });
    }
    const res = mobile() ? mobileVer : nonMobileVer;
    return res;
}

PostTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PostTable);